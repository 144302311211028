import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import attendanceReducer from "./attendance/reducers";
import personalDetailsReducer from './persionaldetails/reducers';
import usersReducer from "./users/reducers";
import employeeReducer from "./commonSaga/webReducers";
import professionalDetailsReducer from "./professionaldetails/reducers";
import userRoleReducer from "./userRoles/reducers";
import userPermissionsReducer from "./userPermissions/reducers";
import familyDetailsReducer from "./familiydetails/reducers";
import bankDetailsReducer from "./bankdetails/reducers";
import educationReducer from "./educationdetails/reducers";
import projectReducer from "./projectdetails/reducers";
import userAttendanceReducer from "./userattendancerecord/reducers";
import attendanceUserDailyReducer from "./dailyuserattendance/reducer";
import experienceReducer from "./experiencedetails/reducers";
import officeReducer from "./office/reducers";
import userRegularizeReducer from "./userregularize/reducers";
import userLeaveReducer from "./userleaveapply/reducers";
import userReimbursementReducer from "./userreimbursement/reducers";
import regularizationReducer from "./adminRegularization/reducers";
import leaveReducer from "./adminLeave/reducers";
import holidayReducer from "./holiday/reducers";
import expenseReducer from "./adminExpense/reducers";
import notificationReducer from "./notification/reducers";

















// Employee Reducers


const rootReducer = combineReducers({
  Auth,
  Layout,
  usersData : usersReducer,
  attendance: attendanceReducer,
  personalDetails: personalDetailsReducer,
  employee: employeeReducer,
  professionalDetails: professionalDetailsReducer,
  userRoles: userRoleReducer,
  userPermissions: userPermissionsReducer,
  familyDetails: familyDetailsReducer,
  bankDetails: bankDetailsReducer,
  educationDetails: educationReducer,
  projectDetails: projectReducer,
  userAttendaceRecord: userAttendanceReducer,
  userDailyAttendance: attendanceUserDailyReducer,
  userExperience: experienceReducer,
  officeData: officeReducer,
  userRegularize: userRegularizeReducer,
  userLeave: userLeaveReducer,
  userReimbursement: userReimbursementReducer,
  regularizationData: regularizationReducer,
  leaveData: leaveReducer,
  holidayData: holidayReducer,
  expenseData: expenseReducer,
  notificationsData: notificationReducer,














  // Employee Reducers


  
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;

