import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_USER_ROLE_REQUEST,
  getUserRoleSuccess,
  getUserRoleFailure,
  ADD_USER_ROLE_REQUEST,
  addUserRoleSuccess,
  addUserRoleFailure,
  ASSIGN_ROLE_REQUEST,
  assignRoleFailure,
  assignRoleSuccess,
} from './actions';
import config from '../../config';
import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

// Saga to fetch user roles
function* getUserRolesSaga() {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/get/user/roles-list`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const data = yield response.json();
    
    if (!response.ok) {
      yield put(getUserRoleFailure(data.message));
    } else {
      yield put(getUserRoleSuccess(data));
    }
  } catch (error) {
    yield put(getUserRoleFailure(error.message));
  }
}

// Saga to add a new user role
function* addUserRoleSaga(action) {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/add/user/role`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();

    if (!response.ok) {
      yield put(addUserRoleFailure(data.message));
    } else {
      yield put(addUserRoleSuccess(data));
    }
  } catch (error) {
    yield put(addUserRoleFailure(error.message));
  }
}

// Saga to assign role to user
function* assignRoleSaga(action) {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/assign/user/role`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();

    if (!response.ok) {
      yield put(assignRoleFailure(data.message));
    } else {
      yield put(assignRoleSuccess(data));
    }
  } catch (error) {
    yield put(assignRoleFailure(error.message));
  }
}

// Watcher Saga for user role actions
export function* watchUserRoleActions() {
  yield takeLatest(GET_USER_ROLE_REQUEST, getUserRolesSaga);
  yield takeLatest(ADD_USER_ROLE_REQUEST, addUserRoleSaga);
  yield takeLatest(ASSIGN_ROLE_REQUEST, assignRoleSaga);
}
