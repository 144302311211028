export const ADD_OFFICE_REQUEST = 'ADD_OFFICE_REQUEST';
export const ADD_OFFICE_SUCCESS = 'ADD_OFFICE_SUCCESS';
export const ADD_OFFICE_FAILURE = 'ADD_OFFICE_FAILURE';

export const UPDATE_OFFICE_REQUEST = 'UPDATE_OFFICE_REQUEST';
export const UPDATE_OFFICE_SUCCESS = 'UPDATE_OFFICE_SUCCESS';
export const UPDATE_OFFICE_FAILURE = 'UPDATE_OFFICE_FAILURE';

export const FETCH_OFFICES_REQUEST = 'FETCH_OFFICES_REQUEST';
export const FETCH_OFFICES_SUCCESS = 'FETCH_OFFICES_SUCCESS';
export const FETCH_OFFICES_FAILURE = 'FETCH_OFFICES_FAILURE';

export const DELETE_OFFICE_REQUEST = 'DELETE_OFFICE_REQUEST';
export const DELETE_OFFICE_SUCCESS = 'DELETE_OFFICE_SUCCESS';
export const DELETE_OFFICE_FAILURE = 'DELETE_OFFICE_FAILURE';

// Actions for adding an office
export const addOfficeRequest = (officeData) => ({
  type: ADD_OFFICE_REQUEST,
  payload: officeData,
});

export const addOfficeSuccess = (newOffice) => ({
  type: ADD_OFFICE_SUCCESS,
  payload: newOffice,
});

export const addOfficeFailure = (error) => ({
  type: ADD_OFFICE_FAILURE,
  payload: error,
});

// Actions for updating an office
export const updateOfficeRequest = (officeData) => ({
  type: UPDATE_OFFICE_REQUEST,
  payload: officeData,
});

export const updateOfficeSuccess = (updatedOffice) => ({
  type: UPDATE_OFFICE_SUCCESS,
  payload: updatedOffice,
});

export const updateOfficeFailure = (error) => ({
  type: UPDATE_OFFICE_FAILURE,
  payload: error,
});

// Actions for fetching all offices
export const fetchOfficesRequest = () => ({
  type: FETCH_OFFICES_REQUEST,
});

export const fetchOfficesSuccess = (offices) => ({
  type: FETCH_OFFICES_SUCCESS,
  payload: offices,
});

export const fetchOfficesFailure = (error) => ({
  type: FETCH_OFFICES_FAILURE,
  payload: error,
});

// Actions for deleting an office
export const deleteOfficeRequest = (officeId) => ({
  type: DELETE_OFFICE_REQUEST,
  payload: officeId,
});

export const deleteOfficeSuccess = (officeId) => ({
  type: DELETE_OFFICE_SUCCESS,
  payload: officeId,
});

export const deleteOfficeFailure = (error) => ({
  type: DELETE_OFFICE_FAILURE,
  payload: error,
});
