// reducer.js
import {
    POST_LEAVE_REQUEST,
    POST_LEAVE_SUCCESS,
    POST_LEAVE_FAILURE,
    FETCH_LEAVE_REQUEST,
    FETCH_LEAVE_SUCCESS,
    FETCH_LEAVE_FAILURE,
  } from "./actions";
  
  const initialState = {
    loading: false,
    data: [],
    error: null,
  };
  
  const userLeaveReducer = (state = initialState, action) => {
    switch (action.type) {
      case POST_LEAVE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case POST_LEAVE_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.response,
        };
      case POST_LEAVE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      case FETCH_LEAVE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_LEAVE_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.data,
        };
      case FETCH_LEAVE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
  
      default:
        return state;
    }
  };
  
  export default userLeaveReducer;
  