import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_ATTENDANCE_REQUEST,
  fetchAttendanceSuccess,
  fetchAttendanceFailure,
  FETCH_ATTENDANCE_OVERVIEW_REQUEST,
  fetchAttendanceOverviewSuccess,
  fetchAttendanceOverviewFailure,
  ADD_ATTENDANCE_REQUEST,
  addAttendanceSuccess,
  addAttendanceFailure,
  EDIT_ATTENDANCE_REQUEST,
  editAttendanceSuccess,
  editAttendanceFailure,
  DELETE_ATTENDANCE_REQUEST,
  deleteAttendanceSuccess,
  deleteAttendanceFailure,
  fetchAttendanceOverviewDetailSuccess,
  fetchAttendanceOverviewDetailsFailure,
  FETCH_ATTENDANCE_OVERVIEW_DETAIL_REQUEST,
} from "./actions";
import config from "../../config";
import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

function* fetchAttendanceSaga(action) {
  try {
    const { month, year } = action.payload;
    const response = yield call(
      fetch,
      `${baseUrl}/auth/get/all-users/attendances-list?month=${month}&year=${year}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = yield response.json();
    yield put(fetchAttendanceSuccess(data));
  } catch (error) {
    yield put(fetchAttendanceFailure(error.message));
  }
}

function* fetchAttendanceOverviewSaga(action) {
  try {
    const { month, year } = action.payload;

    const response = yield call(
      fetch,
      `${baseUrl}/admin/get/attendance/monthly/overview?month=${month}&year=${year}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = yield response.json();
    yield put(fetchAttendanceOverviewSuccess(data));
  } catch (error) {
    yield put(fetchAttendanceOverviewFailure(error.message));
  }
}

function* fetchAttendanceOverviewDetailSaga(action) {
  try {
    const { user_id } = action.payload;
    
    const response = yield call(
      fetch,
      `${baseUrl}/admin/get/attendance/monthly/report?user_id=${user_id.userId}&month=${user_id.month}&year=${user_id.year}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = yield response.json();
    yield put(fetchAttendanceOverviewDetailSuccess(data));
  } catch (error) {
    yield put(fetchAttendanceOverviewDetailsFailure(error.message));
  }
}

function* addAttendanceSaga(action) {
  
  try {
    const response = yield call(
      fetch,
      `${baseUrl}/auth/admin/add/attendance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(action.payload),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = yield response.json();
    yield put(addAttendanceSuccess(data));
  } catch (error) {
    yield put(addAttendanceFailure(error.message));
  }
}

function* editAttendanceSaga(action) {
  try {
    const { attendanceId, updatedData } = action.payload;
    const response = yield call(
      fetch,
      `${baseUrl}/auth/admin/edit/attendance/${attendanceId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = yield response.json();
    yield put(editAttendanceSuccess(data));
  } catch (error) {
    yield put(editAttendanceFailure(error.message));
  }
}

function* deleteAttendanceSaga(action) {
  try {
    const { attendanceId } = action.payload;
    const response = yield call(
      fetch,
      `${baseUrl}/auth/delete/user/attendance/${attendanceId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    yield put(deleteAttendanceSuccess(attendanceId));
  } catch (error) {
    yield put(deleteAttendanceFailure(error.message));
  }
}

export function* watchFetchAttendance() {
  yield takeLatest(FETCH_ATTENDANCE_REQUEST, fetchAttendanceSaga);
}

export function* watchFetchAttendanceOverview() {
  yield takeLatest(FETCH_ATTENDANCE_OVERVIEW_REQUEST, fetchAttendanceOverviewSaga);
}
export function* watchFetchAttendanceOverviewDetail() {
  yield takeLatest(FETCH_ATTENDANCE_OVERVIEW_DETAIL_REQUEST, fetchAttendanceOverviewDetailSaga);
}

export function* watchAddAttendance() {
  yield takeLatest(ADD_ATTENDANCE_REQUEST, addAttendanceSaga);
}

export function* watchEditAttendance() {
  yield takeLatest(EDIT_ATTENDANCE_REQUEST, editAttendanceSaga);
}

export function* watchDeleteAttendance() {
  yield takeLatest(DELETE_ATTENDANCE_REQUEST, deleteAttendanceSaga);
}
