// persionaldetails/actions.js
export const FETCH_BANK_DETAILS_REQUEST = 'FETCH_BANK_DETAILS_REQUEST';
export const FETCH_BANK_DETAILS_SUCCESS = 'FETCH_BANK_DETAILS_SUCCESS';
export const FETCH_BANK_DETAILS_FAILURE = 'FETCH_BANK_DETAILS_FAILURE';

export const UPDATE_BANK_DETAILS_REQUEST = 'UPDATE_BANK_DETAILS_REQUEST';
export const UPDATE_BANK_DETAILS_SUCCESS = 'UPDATE_BANK_DETAILS_SUCCESS';
export const UPDATE_BANK_DETAILS_FAILURE = 'UPDATE_BANK_DETAILS_FAILURE';

// persionaldetails/actions.js
export const fetchBankDetailsRequest = (payload) => ({
  type: FETCH_BANK_DETAILS_REQUEST,
  payload: payload,
});


export const fetchBankDetailsSuccess = (data) => ({
  type: FETCH_BANK_DETAILS_SUCCESS,
  payload: data,
});

export const fetchBankDetailsFailure = (error) => ({
  type: FETCH_BANK_DETAILS_FAILURE,
  payload: error,
});




export const updateBankDetailsRequest = (payload) => ({
  type: UPDATE_BANK_DETAILS_REQUEST,
  payload: payload,
});

export const updateBankDetailsSuccess = (data) => ({
  type: UPDATE_BANK_DETAILS_SUCCESS,
  payload: data,
});

export const updateBankDetailsFailure = (error) => ({
  type: UPDATE_BANK_DETAILS_FAILURE,
  payload: error,
});
