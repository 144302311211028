export const ADD_HOLIDAY_REQUEST = 'ADD_HOLIDAY_REQUEST';
export const ADD_HOLIDAY_SUCCESS = 'ADD_HOLIDAY_SUCCESS';
export const ADD_HOLIDAY_FAILURE = 'ADD_HOLIDAY_FAILURE';

export const UPDATE_HOLIDAY_REQUEST = 'UPDATE_HOLIDAY_REQUEST';
export const UPDATE_HOLIDAY_SUCCESS = 'UPDATE_HOLIDAY_SUCCESS';
export const UPDATE_HOLIDAY_FAILURE = 'UPDATE_HOLIDAY_FAILURE';

export const FETCH_HOLIDAYS_REQUEST = 'FETCH_HOLIDAYS_REQUEST';
export const FETCH_HOLIDAYS_SUCCESS = 'FETCH_HOLIDAYS_SUCCESS';
export const FETCH_HOLIDAYS_FAILURE = 'FETCH_HOLIDAYS_FAILURE';

export const DELETE_HOLIDAY_REQUEST = 'DELETE_HOLIDAY_REQUEST';
export const DELETE_HOLIDAY_SUCCESS = 'DELETE_HOLIDAY_SUCCESS';
export const DELETE_HOLIDAY_FAILURE = 'DELETE_HOLIDAY_FAILURE';


// Actions for adding a holiday
export const addHolidayRequest = (holidayData) => ({
    type: ADD_HOLIDAY_REQUEST,
    payload: holidayData,
  });
  
  export const addHolidaySuccess = (newHoliday) => ({
    type: ADD_HOLIDAY_SUCCESS,
    payload: newHoliday,
  });
  
  export const addHolidayFailure = (error) => ({
    type: ADD_HOLIDAY_FAILURE,
    payload: error,
  });
  
  // Actions for updating a holiday
  export const updateHolidayRequest = (holidayData) => ({
    type: UPDATE_HOLIDAY_REQUEST,
    payload: holidayData,
  });
  
  export const updateHolidaySuccess = (updatedHoliday) => ({
    type: UPDATE_HOLIDAY_SUCCESS,
    payload: updatedHoliday,
  });
  
  export const updateHolidayFailure = (error) => ({
    type: UPDATE_HOLIDAY_FAILURE,
    payload: error,
  });
  
  // Actions for fetching all holidays
  export const fetchHolidaysRequest = () => ({
    type: FETCH_HOLIDAYS_REQUEST,
  });
  
  export const fetchHolidaysSuccess = (holidays) => ({
    type: FETCH_HOLIDAYS_SUCCESS,
    payload: holidays,
  });
  
  export const fetchHolidaysFailure = (error) => ({
    type: FETCH_HOLIDAYS_FAILURE,
    payload: error,
  });
  
  // Actions for deleting a holiday
  export const deleteHolidayRequest = (holidayId) => ({
    type: DELETE_HOLIDAY_REQUEST,
    payload: holidayId,
  });
  
  export const deleteHolidaySuccess = (holidayId) => ({
    type: DELETE_HOLIDAY_SUCCESS,
    payload: holidayId,
  });
  
  export const deleteHolidayFailure = (error) => ({
    type: DELETE_HOLIDAY_FAILURE,
    payload: error,
  });
  