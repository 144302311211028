export const ADD_EMPLOYEE_REQUEST = 'ADD_EMPLOYEE_REQUEST';
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';
export const ADD_EMPLOYEE_FAILURE = 'ADD_EMPLOYEE_FAILURE';

export const EDIT_EMPLOYEE_REQUEST = 'EDIT_EMPLOYEE_REQUEST';
export const EDIT_EMPLOYEE_SUCCESS = 'EDIT_EMPLOYEE_SUCCESS';
export const EDIT_EMPLOYEE_FAILURE = 'EDIT_EMPLOYEE_FAILURE';

export const DELETE_EMPLOYEE_REQUEST = 'DELETE_EMPLOYEE_REQUEST';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAILURE = 'DELETE_EMPLOYEE_FAILURE';

export const UPDATE_USER_STATUS_REQUEST = "UPDATE_USER_STATUS_REQUEST";
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";
export const UPDATE_USER_STATUS_FAILURE = "UPDATE_USER_STATUS_FAILURE";

// Actions for adding an employee
export const addEmployeeRequest = (formData) => ({
  type: ADD_EMPLOYEE_REQUEST,
  payload: formData,
});

export const addEmployeeSuccess = (data) => ({
  type: ADD_EMPLOYEE_SUCCESS,
  payload: data,
});

export const addEmployeeFailure = (error) => ({
  type: ADD_EMPLOYEE_FAILURE,
  payload: error,
});

// Actions for editing an employee
export const editEmployeeRequest = (employeeData) => ({
  type: EDIT_EMPLOYEE_REQUEST,
  payload: employeeData,
});

export const editEmployeeSuccess = (updatedEmployee) => ({
  type: EDIT_EMPLOYEE_SUCCESS,
  payload: updatedEmployee,
});

export const editEmployeeFailure = (error) => ({
  type: EDIT_EMPLOYEE_FAILURE,
  payload: error,
});

// Actions for deleting an employee

export const deleteEmployeeRequest = (employeeId) => ({
  type: DELETE_EMPLOYEE_REQUEST,
  payload: employeeId,
});

export const deleteEmployeeSuccess = (employeeId) => ({
  type: DELETE_EMPLOYEE_SUCCESS,
  payload: employeeId,
});

export const deleteEmployeeFailure = (error) => ({
  type: DELETE_EMPLOYEE_FAILURE,
  payload: error,
});

// Actions for updating user status

export const updateUserStatusRequest = (statusData) => ({
  type: UPDATE_USER_STATUS_REQUEST,
  payload: statusData,
});

export const updateUserStatusSuccess = (data) => ({
  type: UPDATE_USER_STATUS_SUCCESS,
  payload: data,
});

export const updateUserStatusFailure = (error) => ({
  type: UPDATE_USER_STATUS_FAILURE,
  payload: error,
});