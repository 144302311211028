import {
  FETCH_EXPENSE_REQUEST,
  FETCH_EXPENSE_SUCCESS,
  FETCH_EXPENSE_FAILURE,
  DELETE_EXPENSE_REQUEST,
  DELETE_EXPENSE_SUCCESS,
  DELETE_EXPENSE_FAILURE,
  UPDATE_EXPENSE_STATUS_REQUEST,
  UPDATE_EXPENSE_STATUS_SUCCESS,
  UPDATE_EXPENSE_STATUS_FAILURE,
} from "./actions";

const initialState = {
  expenses: [],
  loading: false,
  error: null,
};

const expenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXPENSE_REQUEST:
    case DELETE_EXPENSE_REQUEST:
    case UPDATE_EXPENSE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        expenses: action.payload,
      };

    case FETCH_EXPENSE_FAILURE:
    case DELETE_EXPENSE_FAILURE:
    case UPDATE_EXPENSE_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        expenses: state.expenses.filter((expense) => expense.id !== action.payload),
      };

    case UPDATE_EXPENSE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        expenses: state.expenses.map((expense) =>
          expense.id === action.payload.reimId
            ? { ...expense, status: action.payload.reim_status, ref_no: action.payload.ref_no }
            : expense
        ),
      };

    default:
      return state;
  }
};

export default expenseReducer;
