import React from "react";
import { Route, Navigate, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const SignInSignUp = React.lazy(() => import("../pages/auth/SignInSignUp"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));


// Admin route imports
const AdminDashboard = React.lazy(() => import("../pages/auth/admin/dashboard/dashboard"));
const Overview = React.lazy(() => import("../pages/auth/admin/overview"));
const OverviewReport = React.lazy(() => import("../pages/auth/admin/overviewReport"));
const AttendanceRecord = React.lazy(() => import("../pages/auth/admin/attendance"));
const Regularization = React.lazy(() => import("../pages/auth/admin/regularization"));
const LeaveRequests = React.lazy(() => import("../pages/auth/admin/leave"));
const ExpenseRequests = React.lazy(() => import("../pages/auth/admin/expense"));
const EmployeeRecords = React.lazy(() => import("../pages/auth/admin/employee_records"));
const HolidayCalendar = React.lazy(() => import("../pages/auth/admin/holidays"));
const UserRoles = React.lazy(() => import("../pages/auth/admin/roles"));
const UserPermissions = React.lazy(() => import("../pages/auth/admin/permissions"));
const OfficeRecord = React.lazy(() => import("../pages/auth/admin/office"));
const Notifications = React.lazy(() => import("../pages/auth/admin/notifications"));



// Employee Route imports
const EmployeeDashboard = React.lazy(() => import("../pages/auth/employee/dashboard"));
const CalendarApp = React.lazy(() => import("../pages/auth/employee/overview"));
const AttendanceRecordEmployee = React.lazy(() => import("../pages/auth/employee/attendance"));
const RegularizationEmployee = React.lazy(() => import("../pages/auth/employee/regularization"));
const LeaveManagement = React.lazy(() => import("../pages/auth/employee/leave"));
const TaskList = React.lazy(() => import("../pages/auth/employee/task")); 
const TimeSheet = React.lazy(() => import("../pages/auth/employee/timesheet"));
const Holiday = React.lazy(() => import("../pages/auth/employee/holiday"));
const Resign = React.lazy(() => import("../pages/auth/employee/Resign"));
const Reimbursement = React.lazy(() => import("../pages/auth/employee/reimbursement"));
const Profile = React.lazy(() => import("../pages/auth/employee/profile"));
const IdentityCard = React.lazy(() => import("../pages/auth/employee/identitycard"));
const Biometrics = React.lazy(() => import("../pages/auth/employee/biometric"));



// extra pages
const StarterDemo = React.lazy(() => import("../pages/auth/admin/dashboard/demo"));
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error404Two = React.lazy(() => import("../pages/error/Error404Two"));
const Error404Alt = React.lazy(() => import("../pages/error/Error404Alt"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
const Error500Two = React.lazy(() => import("../pages/error/Error500Two"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}


// Admin Routes 
const adminBasePath = "/auth/admin";
const adminRoutes: RoutesProps = {
  path: "/auth/admin",
  name: "Admin",
  icon: "settings",
  // header: "Navigation",
  children: [
    {
      path: "/",
      name: "Root",
      element: <Navigate to={`${adminBasePath}/dashboard/overview`} />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: `${adminBasePath}/dashboard/analytics`,
      name: "Admin Dashboard",
      element: <AdminDashboard />,
      route: PrivateRoute,
       roles: ["Admin"],
    },
    {
      path: `${adminBasePath}/dashboard/overview`,
      name: "Overview",
      element: <Overview />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: `${adminBasePath}/dashboard/overview/report`,
      name: "Overview Report",
      element: <OverviewReport />,
      route: PrivateRoute,
      roles: ["Admin"],
    },
    {
      path: `${adminBasePath}/attendance/record`,
      name: "Attendance Record",
      element: <AttendanceRecord />,
      route: PrivateRoute,
    },
    {
      path: `${adminBasePath}/attendance/regularization`,
      name: "Attendance Regularization",
      element: <Regularization />,
      route: PrivateRoute,
    },
    {
      path: `${adminBasePath}/leave/requests`,
      name: "Leave Requests",
      element: <LeaveRequests />,
      route: PrivateRoute,
    },
    {
      path: `${adminBasePath}/expense/requests`,
      name: "Expense Requests",
      element: <ExpenseRequests />,
      route: PrivateRoute,
    },
    {
      path: `${adminBasePath}/employee/record`,
      name: "Employee Record",
      element: <EmployeeRecords />,
      route: PrivateRoute,
    },
    {
      path: `${adminBasePath}/holiday/calendar`,
      name: "Holiday Calendar",
      element: <HolidayCalendar />,
      route: PrivateRoute,
    },
    {
      path: `${adminBasePath}/user/roles`,
      name: "User Roles",
      element: <UserRoles />,
      route: PrivateRoute,
    },
    {
      path: `${adminBasePath}/user/permissions`,
      name: "User Permissions",
      element: <UserPermissions />,
      route: PrivateRoute,
    },
    {
      path: `${adminBasePath}/test/demo`,
      name: "StarterDemo",
      element: <StarterDemo />,
      route: PrivateRoute,
    },
    {
      path: `${adminBasePath}/office/record`,
      name: "Office Record",
      element: <OfficeRecord />,
      route: PrivateRoute,
    },
    {
      path: `${adminBasePath}/notifications/list/`,
      name: "All Notifications",
      element: <Notifications />,
      route: PrivateRoute,
    },
  ],
};

// Employees Routes 
const employeeBasePath = "/auth/employee";
const employeeRoutes: RoutesProps = {
  path: "/auth/employee",
  name: "Employee",
  icon: "user",
  // header: "Navigation",
  children: [
    // {
    //   path: "/",
    //   name: "Root",
    //   element: <Navigate to={`${employeeBasePath}/dashboard/overview`} />,
    //   route: PrivateRoute,
    // },
    {
      path: `${employeeBasePath}/dashboard/overview`,
      name: "Employee Dashboard",
      element: <EmployeeDashboard />,
      route: PrivateRoute,
      roles: ["Emp"],
    },
    // {
    //   path: `${employeeBasePath}/test/demo`,
    //   name: "StarterDemo",
    //   element: <StarterDemo />,
    //   route: PrivateRoute,
    // },
    {
      path: `${employeeBasePath}/attendance/overview`,
      name: "Calender",
      element: <CalendarApp />,
      route: PrivateRoute,
      roles: ["Emp"],
    },
    {
      path: `${employeeBasePath}/attendance/records`,
      name: "Attendance Record",
      element: <AttendanceRecordEmployee />,
      route: PrivateRoute,
    },
    {
      path: `${employeeBasePath}/attendance/regularization`,
      name: "Attendance Regularization",
      element: <RegularizationEmployee />,
      route: PrivateRoute,
    },
    {
      path: `${employeeBasePath}/leave/status`,
      name: "Attendance Regularization",
      element: <LeaveManagement />,
      route: PrivateRoute,
    },
    {
      path: `${employeeBasePath}/tasks/list`,
      name: "Attendance Regularization",
      element: <TaskList />,
      route: PrivateRoute,
    },
    {
      path: `${employeeBasePath}/timesheet/entries`,
      name: "Attendance Regularization",
      element: <TimeSheet />,
      route: PrivateRoute,
    },
    {
      path: `${employeeBasePath}/resignation/apply`,
      name: "Resignation",
      element: <Resign />,
      route: PrivateRoute,
    },
    {
      path: `${employeeBasePath}/reimbursement/apply`,
      name: "Reimbursement",
      element: <Reimbursement />,
      route: PrivateRoute,
    },
    {
      path: `${employeeBasePath}/holidays/list`,
      name: "Holiday",
      element: <Holiday />,
      route: PrivateRoute,
    },
    {
      path: `${employeeBasePath}/biometric`,
      name: "Biometric",
      element: <Biometrics />,
      route: PrivateRoute,
    },
    {
      path: `${employeeBasePath}/profile/view`,
      name: "Profile",
      element: <Profile />,
      route: PrivateRoute,
    },
    {
      path: `${employeeBasePath}/profile/identity-card`,
      name: "Profile",
      element: <IdentityCard />,
      route: PrivateRoute,
    },
  ],
};


// pages
const extrapagesRoutes = {
  path: "/pages",
  name: "Pages",
  icon: "package",
  header: "Custom",
  children: [
    {
      path: "/pages/error-404-alt",
      name: "Error - 404-alt",
      element: <Error404Alt />,
      route: PrivateRoute,
    },
  ],
};


// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/auth/signin-signup",
    name: "SignIn-SignUp",
    element: <SignInSignUp />,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes = [
  {
    path: "/error-404",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/error-404-two",
    name: "Error - 404 Two",
    element: <Error404Two />,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
  {
    path: "/error-500-two",
    name: "Error - 500 Two",
    element: <Error500Two />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  adminRoutes,
  employeeRoutes,
  extrapagesRoutes,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
