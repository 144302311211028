export const FETCH_EXPENSE_REQUEST = "FETCH_EXPENSE_REQUEST";
export const FETCH_EXPENSE_SUCCESS = "FETCH_EXPENSE_SUCCESS";
export const FETCH_EXPENSE_FAILURE = "FETCH_EXPENSE_FAILURE";

export const DELETE_EXPENSE_REQUEST = "DELETE_EXPENSE_REQUEST";
export const DELETE_EXPENSE_SUCCESS = "DELETE_EXPENSE_SUCCESS";
export const DELETE_EXPENSE_FAILURE = "DELETE_EXPENSE_FAILURE";

export const UPDATE_EXPENSE_STATUS_REQUEST = "UPDATE_EXPENSE_STATUS_REQUEST";
export const UPDATE_EXPENSE_STATUS_SUCCESS = "UPDATE_EXPENSE_STATUS_SUCCESS";
export const UPDATE_EXPENSE_STATUS_FAILURE = "UPDATE_EXPENSE_STATUS_FAILURE";

// Fetch Actions
export const fetchExpenseRequest = () => ({
  type: FETCH_EXPENSE_REQUEST,
});

export const fetchExpenseSuccess = (data) => ({
  type: FETCH_EXPENSE_SUCCESS,
  payload: data,
});

export const fetchExpenseFailure = (error) => ({
  type: FETCH_EXPENSE_FAILURE,
  payload: error,
});

// Delete Actions
export const deleteExpenseRequest = (id) => ({
  type: DELETE_EXPENSE_REQUEST,
  payload: id,
});

export const deleteExpenseSuccess = (id) => ({
  type: DELETE_EXPENSE_SUCCESS,
  payload: id,
});

export const deleteExpenseFailure = (error) => ({
  type: DELETE_EXPENSE_FAILURE,
  payload: error,
});

// Update Status Actions
export const updateExpenseStatusRequest = (data) => ({
  type: UPDATE_EXPENSE_STATUS_REQUEST,
  payload: data,
});

export const updateExpenseStatusSuccess = (data) => ({
  type: UPDATE_EXPENSE_STATUS_SUCCESS,
  payload: data,
});

export const updateExpenseStatusFailure = (error) => ({
  type: UPDATE_EXPENSE_STATUS_FAILURE,
  payload: error,
});
