export const GET_USER_ROLE_REQUEST = 'GET_USER_ROLE_REQUEST';
export const GET_USER_ROLE_SUCCESS = 'GET_USER_ROLE_SUCCESS';
export const GET_USER_ROLE_FAILURE = 'GET_USER_ROLE_FAILURE';

export const ADD_USER_ROLE_REQUEST = 'ADD_USER_ROLE_REQUEST';
export const ADD_USER_ROLE_SUCCESS = 'ADD_USER_ROLE_SUCCESS';
export const ADD_USER_ROLE_FAILURE = 'ADD_USER_ROLE_FAILURE';

export const ASSIGN_ROLE_REQUEST = 'ASSIGN_ROLE_REQUEST';
export const ASSIGN_ROLE_SUCCESS = 'ASSIGN_ROLE_SUCCESS';
export const ASSIGN_ROLE_FAILURE = 'ASSIGN_ROLE_FAILURE';

export const getUserRoleRequest = () => ({
    type: GET_USER_ROLE_REQUEST,
  });
  
  export const getUserRoleSuccess = (data) => ({
    type: GET_USER_ROLE_SUCCESS,
    payload: data,
  });
  
  export const getUserRoleFailure = (error) => ({
    type: GET_USER_ROLE_FAILURE,
    payload: error,
  });

  export const addUserRoleRequest = (roleData) => ({
    type: ADD_USER_ROLE_REQUEST,
    payload: roleData,
  });
  
  export const addUserRoleSuccess = (data) => ({
    type: ADD_USER_ROLE_SUCCESS,
    payload: data,
  });
  
  export const addUserRoleFailure = (error) => ({
    type: ADD_USER_ROLE_FAILURE,
    payload: error,
  });


  export const assignRoleRequest = (roleData) => ({
    type: ASSIGN_ROLE_REQUEST,
    payload: roleData,
  });
  
  export const assignRoleSuccess = (data) => ({
    type: ASSIGN_ROLE_SUCCESS,
    payload: data,
  });
  
  export const assignRoleFailure = (error) => ({
    type: ASSIGN_ROLE_FAILURE,
    payload: error,
  });
  