//check in check out

export const TOGGLE_CHECK_IN_OUT_REQUEST = 'TOGGLE_CHECK_IN_OUT_REQUEST';
export const TOGGLE_CHECK_IN_OUT_SUCCESS = 'TOGGLE_CHECK_IN_OUT_SUCCESS';
export const TOGGLE_CHECK_IN_OUT_FAILURE = 'TOGGLE_CHECK_IN_OUT_FAILURE';

export const toggleCheckInOutRequest = (payload) => ({
  type: TOGGLE_CHECK_IN_OUT_REQUEST,
  payload,
});

export const toggleCheckInOutSuccess = (data) => ({
  type: TOGGLE_CHECK_IN_OUT_SUCCESS,
  payload: data,
});

export const toggleCheckInOutFailure = (error) => ({
  type: TOGGLE_CHECK_IN_OUT_FAILURE,
  payload: error,
});




export const FETCH_USER_DAILY_ATTENDANCE_REQUEST  = 'FETCH_USER_DAILY_ATTENDANCE_REQUEST';
export const FETCH_USER_DAILY_ATTENDANCE_SUCCESS = 'FETCH_USER_DAILY_ATTENDANCE_SUCCESS';
export const FETCH_USER_DAILY_ATTENDANCE_FAILURE = 'FETCH_USER_DAILY_ATTENDANCE_FAILURE';


export const fetchUserDailyAttendanceRequest = () => ({
  type: FETCH_USER_DAILY_ATTENDANCE_REQUEST,
});

export const fetchUserDailyAttendanceSuccess = (data) => ({
  type: FETCH_USER_DAILY_ATTENDANCE_SUCCESS,
  payload: data, 
});

export const fetchUserDailyAttendanceFailure = (error) => ({
  type: FETCH_USER_DAILY_ATTENDANCE_FAILURE,
  payload: error,
});



