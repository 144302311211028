import {
    FETCH_ALL_LEAVE_SUCCESS,
    FETCH_ALL_LEAVE_FAILURE,
    DELETE_ALL_LEAVE_SUCCESS,
    DELETE_ALL_LEAVE_FAILURE,
    UPDATE_ALL_LEAVE_STATUS_SUCCESS,
    UPDATE_ALL_LEAVE_STATUS_FAILURE,
  } from './actions';
  
  const initialState = {
    data: [],
    error: null,
  };
  
  const leaveReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ALL_LEAVE_SUCCESS:
        return { ...state, data: action.payload, error: null };
  
      case FETCH_ALL_LEAVE_FAILURE:
      case DELETE_ALL_LEAVE_FAILURE:
      case UPDATE_ALL_LEAVE_STATUS_FAILURE:
        return { ...state, error: action.payload };
  
      case DELETE_ALL_LEAVE_SUCCESS:
        return {
          ...state,
          data: state.data.filter((item) => item.id !== action.payload),
        };
  
      case UPDATE_ALL_LEAVE_STATUS_SUCCESS:
        return {
          ...state,
          data: state.data.map((item) =>
            item.id === action.payload.leave_id
              ? { ...item, status: action.payload.status }
              : item
          ),
        };
  
      default:
        return state;
    }
  };
  
  export default leaveReducer;
  