// reducer.js
import {
  POST_REGULARIZE_REQUEST,
  POST_REGULARIZE_SUCCESS,
  POST_REGULARIZE_FAILURE,
  FETCH_REGULARIZE_REQUEST,
  FETCH_REGULARIZE_SUCCESS,
  FETCH_REGULARIZE_FAILURE,
} from "./actions";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const userRegularizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_REGULARIZE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case POST_REGULARIZE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response,
      };
    case POST_REGULARIZE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_REGULARIZE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_REGULARIZE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case FETCH_REGULARIZE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default userRegularizeReducer;
