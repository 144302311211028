import React from "react";
import { Route, Navigate, RouteProps } from "react-router-dom";
import { APICore } from "../helpers/api/apiCore";

const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {
  const api = new APICore();
  const user = api.getLoggedInUser();

  return (
    <Route
      {...rest}
      render={(props: RouteProps) => {
        if (!api.isUserAuthenticated()) {
          return (
            <Navigate
              to={{
                pathname: "/auth/login",
              }}
            />
          );
        }

        if (roles && roles.indexOf(user.role) === -1) {
          return <Navigate to={user.role === "Admin" ? "/auth/admin/dashboard/overview" : "/auth/employee/biometric"} />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
