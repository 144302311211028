// actions.js
export const POST_LEAVE_REQUEST = "POST_LEAVE_REQUEST";
export const POST_LEAVE_SUCCESS = "POST_LEAVE_SUCCESS";
export const POST_LEAVE_FAILURE = "POST_LEAVE_FAILURE";

export const postLeaveequest = (payload) => ({
  type: POST_LEAVE_REQUEST,
  payload,
});

export const postLeaveSuccess = (response) => ({
  type: POST_LEAVE_SUCCESS,
  response,
});

export const postLeaveFailure = (error) => ({
  type: POST_LEAVE_FAILURE,
  error,
});


//fetch
// actions.js
export const FETCH_LEAVE_REQUEST = "FETCH_LEAVE_REQUEST";
export const FETCH_LEAVE_SUCCESS = "FETCH_LEAVE_SUCCESS";
export const FETCH_LEAVE_FAILURE = "FETCH_LEAVE_FAILURE";

export const fetchLeaveRequest = () => ({
  type: FETCH_LEAVE_REQUEST,
});

export const fetchLeaveSuccess = (data) => ({
  type: FETCH_LEAVE_SUCCESS,
  data,
});

export const fetchLeaveFailure = (error) => ({
  type: FETCH_LEAVE_FAILURE,
  error,
});
