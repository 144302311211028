export const FETCH_ATTENDANCE_REQUEST = 'FETCH_ATTENDANCE_REQUEST';
export const FETCH_ATTENDANCE_SUCCESS = 'FETCH_ATTENDANCE_SUCCESS';
export const FETCH_ATTENDANCE_FAILURE = 'FETCH_ATTENDANCE_FAILURE';

export const FETCH_ATTENDANCE_OVERVIEW_REQUEST = 'FETCH_ATTENDANCE_OVERVIEW_REQUEST';
export const FETCH_ATTENDANCE_OVERVIEW_SUCCESS = 'FETCH_ATTENDANCE_OVERVIEW_SUCCESS';
export const FETCH_ATTENDANCE_OVERVIEW_FAILURE = 'FETCH_ATTENDANCE_OVERVIEW_FAILURE';

export const FETCH_ATTENDANCE_OVERVIEW_DETAIL_REQUEST = 'FETCH_ATTENDANCE_OVERVIEW_DETAIL_REQUEST';
export const FETCH_ATTENDANCE_OVERVIEW_DETAIL_SUCCESS = 'FETCH_ATTENDANCE_OVERVIEW_DETAIL_SUCCESS';
export const FETCH_ATTENDANCE_OVERVIEW_DETAIL_FAILURE = 'FETCH_ATTENDANCE_OVERVIEW_DETAIL_FAILURE';

export const ADD_ATTENDANCE_REQUEST = 'ADD_ATTENDANCE_REQUEST';
export const ADD_ATTENDANCE_SUCCESS = 'ADD_ATTENDANCE_SUCCESS';
export const ADD_ATTENDANCE_FAILURE = 'ADD_ATTENDANCE_FAILURE';

export const EDIT_ATTENDANCE_REQUEST = 'EDIT_ATTENDANCE_REQUEST';
export const EDIT_ATTENDANCE_SUCCESS = 'EDIT_ATTENDANCE_SUCCESS';
export const EDIT_ATTENDANCE_FAILURE = 'EDIT_ATTENDANCE_FAILURE';

export const DELETE_ATTENDANCE_REQUEST = 'DELETE_ATTENDANCE_REQUEST';
export const DELETE_ATTENDANCE_SUCCESS = 'DELETE_ATTENDANCE_SUCCESS';
export const DELETE_ATTENDANCE_FAILURE = 'DELETE_ATTENDANCE_FAILURE';

export const fetchAttendanceRequest = (month, year) => ({
  type: FETCH_ATTENDANCE_REQUEST,
  payload: { month, year },
});

export const fetchAttendanceSuccess = (data) => ({
  type: FETCH_ATTENDANCE_SUCCESS,
  payload: data,
});

export const fetchAttendanceFailure = (error) => ({
  type: FETCH_ATTENDANCE_FAILURE,
  payload: error,
});

export const fetchAttendanceOverviewRequest = (month, year) => ({
  type: FETCH_ATTENDANCE_OVERVIEW_REQUEST,
  payload: { month, year },
});

export const fetchAttendanceOverviewSuccess = (data) => ({
  type: FETCH_ATTENDANCE_OVERVIEW_SUCCESS,
  payload: data,
});

export const fetchAttendanceOverviewFailure = (error) => ({
  type: FETCH_ATTENDANCE_OVERVIEW_FAILURE,
  payload: error,
});

// Admin fetch attendance overview details actions
export const fetchAttendanceOverviewDetailRequest = (user_id, month, year) => ({
  type: FETCH_ATTENDANCE_OVERVIEW_DETAIL_REQUEST,
  payload: { user_id, month, year },
});

export const fetchAttendanceOverviewDetailSuccess = (data) => ({
  type: FETCH_ATTENDANCE_OVERVIEW_DETAIL_SUCCESS,
  payload: data,
});

export const fetchAttendanceOverviewDetailsFailure = (error) => ({
  type: FETCH_ATTENDANCE_OVERVIEW_DETAIL_FAILURE,
  payload: error,
});

// Admin attendance actions
export const addAttendanceRequest = (data) => ({
  type: ADD_ATTENDANCE_REQUEST,
  payload: data,
});

export const addAttendanceSuccess = (data) => ({
  type: ADD_ATTENDANCE_SUCCESS,
  payload: data,
});

export const addAttendanceFailure = (error) => ({
  type: ADD_ATTENDANCE_FAILURE,
  payload: error,
});

export const editAttendanceRequest = (attendanceId, updatedData) => ({
  type: EDIT_ATTENDANCE_REQUEST,
  payload: { attendanceId, updatedData },
});

export const editAttendanceSuccess = (data) => ({
  type: EDIT_ATTENDANCE_SUCCESS,
  payload: data,
});

export const editAttendanceFailure = (error) => ({
  type: EDIT_ATTENDANCE_FAILURE,
  payload: error,
});

export const deleteAttendanceRequest = (attendanceId) => ({
  type: DELETE_ATTENDANCE_REQUEST,
  payload: { attendanceId },
});

export const deleteAttendanceSuccess = (attendanceId) => ({
  type: DELETE_ATTENDANCE_SUCCESS,
  payload: { attendanceId },
});

export const deleteAttendanceFailure = (error) => ({
  type: DELETE_ATTENDANCE_FAILURE,
  payload: error,
});
