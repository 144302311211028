import {
  ADD_EXPERIENCE_REQUEST,
  ADD_EXPERIENCE_SUCCESS,
  ADD_EXPERIENCE_FAILURE,
  EDIT_EXPERIENCE_REQUEST,
  EDIT_EXPERIENCE_SUCCESS,
  EDIT_EXPERIENCE_FAILURE,
  DELETE_EXPERIENCE_SUCCESS,
  FETCH_EXPERIENCE_REQUEST,
  FETCH_EXPERIENCE_SUCCESS,
  FETCH_EXPERIENCE_FAILURE,
} from "./actions";

const initialState = {
  success: false,
  error: null,
  data: [],
};

const experienceReducer = (state = initialState, action) => {
  switch (action.type) {
    // add
    case ADD_EXPERIENCE_REQUEST:
      return {
        ...state,
        success: false,
        error: null,
      };
    case ADD_EXPERIENCE_SUCCESS:
      return {
        ...state,
        success: true,
        data: Array.isArray(state.data) ? [...state.data, action.payload] : [action.payload],
      };
    case ADD_EXPERIENCE_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
      };
    case FETCH_EXPERIENCE_REQUEST:
      return {
        ...state,
        success: false,
        error: null, // Clear any previous error
      };

    case FETCH_EXPERIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null, // No error on success
        data: action.payload,
      };

    case FETCH_EXPERIENCE_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload, // Set the error message
      };

    //   EDIT
    case EDIT_EXPERIENCE_REQUEST:
      return {
        ...state,
        success: false,
        error: null,
      };
    case EDIT_EXPERIENCE_SUCCESS:
      console.log("Type of state.data:", typeof state.data);
      console.log("state.data:", state.data);
      return {
        ...state,
        success: true,
        data: Array.isArray(state.data)
          ? state.data.map((experience) =>
              experience.id === action.payload.id ? action.payload : experience
            )
          : [],
      };

    case EDIT_EXPERIENCE_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
      };

    //   Delete
    case DELETE_EXPERIENCE_SUCCESS:
      return {
        ...state,
        // data: state.data.filter((EXPERIENCE) => EXPERIENCE.id !== action.payload),
        data: state.data.filter((item) => item.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export default experienceReducer;
