import {
    FETCH_REGULARIZATION_SUCCESS,
    FETCH_REGULARIZATION_FAILURE,
    DELETE_REGULARIZATION_SUCCESS,
    DELETE_REGULARIZATION_FAILURE,
    UPDATE_REGULARIZATION_STATUS_SUCCESS,
    UPDATE_REGULARIZATION_STATUS_FAILURE,
  } from './actions';
  
  const initialState = {
    data: [],
    error: null,
  };
  
  const regularizationReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REGULARIZATION_SUCCESS:
        return { ...state, data: action.payload, error: null };
  
      case FETCH_REGULARIZATION_FAILURE:
      case DELETE_REGULARIZATION_FAILURE:
      case UPDATE_REGULARIZATION_STATUS_FAILURE:
        return { ...state, error: action.payload };
  
      case DELETE_REGULARIZATION_SUCCESS:
        return {
          ...state,
          data: state.data.filter((item) => item.id !== action.payload),
        };
  
      case UPDATE_REGULARIZATION_STATUS_SUCCESS:
        return {
          ...state,
          data: state.data.map((item) =>
            item.att_id === action.payload.att_id
              ? { ...item, status: action.payload.type === "approve" ? "Approved" : "Rejected" }
              : item
          ),
        };
  
      default:
        return state;
    }
  };
  
  export default regularizationReducer;
  