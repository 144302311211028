import {
    ADD_PROJECT_REQUEST,
    ADD_PROJECT_SUCCESS,
    ADD_PROJECT_FAILURE,
    EDIT_PROJECT_REQUEST,
    EDIT_PROJECT_SUCCESS,
    EDIT_PROJECT_FAILURE,
    DELETE_PROJECT_SUCCESS,
    FETCH_PROJECT_REQUEST,
    FETCH_PROJECT_SUCCESS,
    FETCH_PROJECT_FAILURE,
  } from "./actions";
  
  const initialState = {
    success: false,
    error: null,
    data: [],
  };
  
  const projectReducer = (state = initialState, action) => {
    switch (action.type) {
      // add
      case ADD_PROJECT_REQUEST:
        return {
          ...state,
          success: false,
          error: null,
        };
      case ADD_PROJECT_SUCCESS:
        return {
          ...state,
          success: true,
          data: [...state.data, action.payload],
        };
      case ADD_PROJECT_FAILURE:
        return {
          ...state,
          success: false,
          error: action.payload,
        };
        case FETCH_PROJECT_REQUEST:
          return {
            ...state,
            success: false,
            error: null, // Clear any previous error
          };
        
        case FETCH_PROJECT_SUCCESS:
          return {
            ...state,
          loading: false,
          success: true,
          error: null,           // No error on success
          data: action.payload,
          };
        
        case FETCH_PROJECT_FAILURE:
          return {
            ...state,
            success: false,
            error: action.payload, // Set the error message
          };
        
      //   EDIT
      case EDIT_PROJECT_REQUEST:
        return {
          ...state,
          success: false,
          error: null,
        };
      case EDIT_PROJECT_SUCCESS:
        return {
          ...state,
          success: true,
          data: state.data.map((project) =>
            project.id === action.payload.id ? action.payload : project
          ),
        };
      case EDIT_PROJECT_FAILURE:
        return {
          ...state,
          success: false,
          error: action.payload,
        };
  
  
      //   Delete
      case DELETE_PROJECT_SUCCESS:
        return {
          ...state,
          // data: state.data.filter((PROJECT) => PROJECT.id !== action.payload),
          data: state.data.filter(item => item.id !== action.payload.id),
        };
      default:
        return state;
    }
  };
  
  export default projectReducer;
  