import { call, put, takeLatest } from 'redux-saga/effects';
import { 
  ADD_EMPLOYEE_REQUEST, 
  addEmployeeSuccess, 
  addEmployeeFailure,
  EDIT_EMPLOYEE_REQUEST,
  editEmployeeSuccess,
  editEmployeeFailure,
  DELETE_EMPLOYEE_REQUEST,
  deleteEmployeeSuccess,
  deleteEmployeeFailure,
  updateUserStatusFailure,
  updateUserStatusSuccess,
  UPDATE_USER_STATUS_REQUEST,
} from './webActions';
import config from '../../config';
import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

function* addEmployeeSaga(action) {
  try {

    const response = yield call(fetch, `${baseUrl}/auth/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    
    if (!response.ok) {
      yield put(addEmployeeFailure(data.message));
    } else {
      yield put(addEmployeeSuccess(data));
    }
  } catch (error) {
    yield put(addEmployeeFailure(error.message));
  }
}

function* editEmployeeSaga(action) {
  try {

    const response = yield call(fetch, `${baseUrl}/auth/user/edit/${action.payload.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();

    if (!response.ok) {
      yield put(editEmployeeFailure(data.message));
    } else {
      yield put(editEmployeeSuccess(data));
    }
  } catch (error) {
    yield put(editEmployeeFailure(error.message));
  }
}

function* deleteEmployeeSaga(action) {
  try {

    const response = yield call(fetch, `${baseUrl}/admin/delete/user/${action.payload}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.ok) {
      yield put(deleteEmployeeSuccess(action.payload));
    } else {
      const data = yield response.json();
      yield put(deleteEmployeeFailure(data.message));
    }
  } catch (error) {
    yield put(deleteEmployeeFailure(error.message));
  }
}

function* updateUserStatusSaga(action) {
  try {
    const response = yield call(fetch, `${baseUrl}/admin/update/user-status/${action.payload.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ status: action.payload.status }),
    });

    const data = yield response.json();

    if (!response.ok) {
      yield put(updateUserStatusFailure(data.message));
    } else {
      yield put(updateUserStatusSuccess(data));
    }
  } catch (error) {
    yield put(updateUserStatusFailure(error.message));
  }
}



export function* watchEmployeeActions() {
  yield takeLatest(ADD_EMPLOYEE_REQUEST, addEmployeeSaga);
  yield takeLatest(EDIT_EMPLOYEE_REQUEST, editEmployeeSaga);
  yield takeLatest(DELETE_EMPLOYEE_REQUEST, deleteEmployeeSaga);
  yield takeLatest(UPDATE_USER_STATUS_REQUEST, updateUserStatusSaga);
}

