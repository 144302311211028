export const ADD_PROJECT_REQUEST = 'ADD_PROJECT_REQUEST';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE';

export const EDIT_PROJECT_REQUEST = 'EDIT_PROJECT_REQUEST';
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';
export const EDIT_PROJECT_FAILURE = 'EDIT_PROJECT_FAILURE';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';

export const FETCH_PROJECT_REQUEST = 'FETCH_PROJECT_REQUEST';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_FAILURE = 'FETCH_PROJECT_FAILURE';    


// Actions for adding an PROJECT
export const addProjectRequest = (formData) => ({
  type: ADD_PROJECT_REQUEST,
  payload: formData,
});

export const addProjectSuccess = (data) => ({
  type: ADD_PROJECT_SUCCESS,
  payload: data,
});

export const addProjectFailure = (error) => ({
  type: ADD_PROJECT_FAILURE,
  payload: error,
});

// Actions for editing an PROJECT
export const editProjectRequest = (projectData) => ({
  type: EDIT_PROJECT_REQUEST,
  payload: projectData,
});

export const editProjectSuccess = (updatedProject) => ({
  type: EDIT_PROJECT_SUCCESS,
  payload: updatedProject,
});

export const editProjectFailure = (error) => ({
  type: EDIT_PROJECT_FAILURE,
  payload: error,
});

// Actions for deleting an PROJECT

export const deleteProjectRequest = (projectId) => ({
  type: DELETE_PROJECT_REQUEST,
  payload: projectId,
});

export const deleteProjectSuccess = (projectId) => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: projectId,
});

export const deleteProjectFailure = (error) => ({
  type: DELETE_PROJECT_FAILURE,
  payload: error,
});

// Actions for updating user status

// Action creator for requesting PROJECT data
export const fetchProjectRequest = (statusData) => ({
  type: FETCH_PROJECT_REQUEST,
  payload: statusData,  // Expecting statusData to contain necessary parameters, like id and status
});

// Action creator for handling a successful response
export const fetchProjectSuccess = (data) => ({
  type: FETCH_PROJECT_SUCCESS,
  payload: data, // Contains the fetched PROJECT data
});

// Action creator for handling an error response
export const fetchProjectFailure = (error) => ({
  type: FETCH_PROJECT_FAILURE,
  payload: error, // Contains the error message or error object
});
