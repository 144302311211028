// persionaldetails/actions.js
export const FETCH_PERSONAL_DETAILS_REQUEST = 'FETCH_PERSONAL_DETAILS_REQUEST';
export const FETCH_PERSONAL_DETAILS_SUCCESS = 'FETCH_PERSONAL_DETAILS_SUCCESS';
export const FETCH_PERSONAL_DETAILS_FAILURE = 'FETCH_PERSONAL_DETAILS_FAILURE';

export const UPDATE_PERSONAL_DETAILS_REQUEST = 'UPDATE_PERSONAL_DETAILS_REQUEST';
export const UPDATE_PERSONAL_DETAILS_SUCCESS = 'UPDATE_PERSONAL_DETAILS_SUCCESS';
export const UPDATE_PERSONAL_DETAILS_FAILURE = 'UPDATE_PERSONAL_DETAILS_FAILURE';

// persionaldetails/actions.js
export const fetchPersonalDetailsRequest = (payload) => ({
  type: FETCH_PERSONAL_DETAILS_REQUEST,
  payload: payload,
});


export const fetchPersonalDetailsSuccess = (data) => ({
  type: FETCH_PERSONAL_DETAILS_SUCCESS,
  payload: data,
});

export const fetchPersonalDetailsFailure = (error) => ({
  type: FETCH_PERSONAL_DETAILS_FAILURE,
  payload: error,
});




export const updatePersonalDetailsRequest = (payload) => ({
  type: UPDATE_PERSONAL_DETAILS_REQUEST,
  payload: payload,
});

export const updatePersonalDetailsSuccess = (data) => ({
  type: UPDATE_PERSONAL_DETAILS_SUCCESS,
  payload: data,
});

export const updatePersonalDetailsFailure = (error) => ({
  type: UPDATE_PERSONAL_DETAILS_FAILURE,
  payload: error,
});
