import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_NOTIFICATION_REQUEST,
  fetchNotificationSuccess,
  fetchNotificationFailure,
} from "./actions";
import config from "../../config";
import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

function* fetchNotifications(action) {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/get/admin/notification`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const data = yield response.json();

    if (!response.ok) {
      yield put(fetchNotificationFailure(data.message));
    } else {
      yield put(fetchNotificationSuccess(data));
    }
  } catch (error) {
    yield put(fetchNotificationFailure(error.message));
  }
}


// Saga Watcher
export function* watchFetchNotifications() {
  yield takeLatest(FETCH_NOTIFICATION_REQUEST, fetchNotifications);
}
