export const GET_PERMISSIONS_REQUEST = 'GET_PERMISSIONS_REQUEST';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAILURE = 'GET_PERMISSIONS_FAILURE';

export const ADD_PERMISSIONS_REQUEST = 'ADD_PERMISSIONS_REQUEST';
export const ADD_PERMISSIONS_SUCCESS = 'ADD_PERMISSIONS_SUCCESS';
export const ADD_PERMISSIONS_FAILURE = 'ADD_PERMISSIONS_FAILURE';

// Action Types for Assigning Permissions
export const ASSIGN_PERMISSION_REQUEST = 'ASSIGN_PERMISSION_REQUEST';
export const ASSIGN_PERMISSION_SUCCESS = 'ASSIGN_PERMISSION_SUCCESS';
export const ASSIGN_PERMISSION_FAILURE = 'ASSIGN_PERMISSION_FAILURE';

export const getPermissionsRequest = () => ({
    type: GET_PERMISSIONS_REQUEST,
  });
  
  export const getPermissionsSuccess = (data) => ({
    type: GET_PERMISSIONS_SUCCESS,
    payload: data,
  });
  
  export const getPermissionsFailure = (error) => ({
    type: GET_PERMISSIONS_FAILURE,
    payload: error,
  });

  export const addPermissionsRequest = (roleData) => ({
    type: ADD_PERMISSIONS_REQUEST,
    payload: roleData,
  });
  
  export const addPermissionsSuccess = (data) => ({
    type: ADD_PERMISSIONS_SUCCESS,
    payload: data,
  });
  
  export const addPermissionsFailure = (error) => ({
    type: ADD_PERMISSIONS_FAILURE,
    payload: error,
  });
  
  // Action Creators for Assigning Permissions
export const assignPermissionRequest = (userId, permissionData) => ({
  type: ASSIGN_PERMISSION_REQUEST,
  payload: { userId, permissionData },
});

export const assignPermissionSuccess = (data) => ({
  type: ASSIGN_PERMISSION_SUCCESS,
  payload: data,
});

export const assignPermissionFailure = (error) => ({
  type: ASSIGN_PERMISSION_FAILURE,
  payload: error,
});