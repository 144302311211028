import { APICore } from "./apiCore";
import config from "../../config";

const api = new APICore();

let baseUrl = config.API_URL

  const users = sessionStorage.getItem('users');
  const parsedUsers = users ? JSON.parse(users) : null;
  const token = parsedUsers ? parsedUsers.token : null;

// account
function login(params: { email: string; password: string }) {

  return fetch(`${baseUrl}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params), 
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json(); 
    })
    .then(data => {
      sessionStorage.setItem('userInfo', JSON.stringify(data));
      return data; 
    })
    .catch(error => {
      console.error('Error:', error); 
      return { success: false, message: error.message }; 
    });  
}




function logout() {
  const baseUrl = "/logout/";

  return api.create(`${baseUrl}`, {}).then(() => {
    localStorage.clear();  
    sessionStorage.clear(); 
    window.location.href = "/login";
  }).catch(error => {
    console.error("Error logging out:", error);
  });
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register/";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string, password: string }) {

  return fetch(`${baseUrl}/auth/user/reset-password`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(params), 
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json(); 
    })
    .then(data => {
      return data; 
    })
    .catch(error => {
      console.error('Error:', error); 
      return { success: false, message: error.message }; 
    });  
  
}

export { login, logout, signup, forgotPassword };
