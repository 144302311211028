import { call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_USER_DAILY_ATTENDANCE_REQUEST, fetchUserDailyAttendanceSuccess, fetchUserDailyAttendanceFailure } from './actions';
import { TOGGLE_CHECK_IN_OUT_REQUEST, toggleCheckInOutSuccess, toggleCheckInOutFailure } from './actions';
import { APICore } from "../../helpers/api/apiCore";
import config from '../../config';

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

function* fetchUserDailyAttendanceSaga() {
  try {

    if (!token) {
      throw new Error("No token found in session storage");
    }

    console.log("Fetching attendance with token:", token);

    const response = yield call(fetch, `${baseUrl}/auth/get/user/todays-attendance`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    console.log("API response:", response);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = yield response.json();
    console.log("Fetched attendance data:", data); 

    yield put(fetchUserDailyAttendanceSuccess(data));  
  } catch (error) {
    console.error("Error fetching attendance:", error);
    yield put(fetchUserDailyAttendanceFailure(error.message));
  }
}


function* toggleCheckInOutSaga(action) {
  try {


    if (!token) {
      throw new Error("No token found in session storage");
    }

    const { email, coordinate, current_address } = action.payload;

    // Make the POST request to toggle check-in/out
    const response = yield call(fetch, `${baseUrl}/auth/add/user/attendance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ email, coordinate, current_address }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = yield response.json();
    yield put(toggleCheckInOutSuccess(data));

  
    yield put({ type: FETCH_USER_DAILY_ATTENDANCE_REQUEST });

  } catch (error) {
    
    yield put(toggleCheckInOutFailure(error.message));
  }
}



export function* watchUserDailyFetchAttendance() {
  yield takeLatest(FETCH_USER_DAILY_ATTENDANCE_REQUEST, fetchUserDailyAttendanceSaga);
  yield takeLatest(TOGGLE_CHECK_IN_OUT_REQUEST, toggleCheckInOutSaga);
}
