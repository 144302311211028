import {
  FETCH_ATTENDANCE_SUCCESS,
  FETCH_ATTENDANCE_FAILURE,
  FETCH_ATTENDANCE_OVERVIEW_SUCCESS,
  FETCH_ATTENDANCE_OVERVIEW_FAILURE,
  ADD_ATTENDANCE_SUCCESS,
  ADD_ATTENDANCE_FAILURE,
  EDIT_ATTENDANCE_SUCCESS,
  EDIT_ATTENDANCE_FAILURE,
  DELETE_ATTENDANCE_SUCCESS,
  DELETE_ATTENDANCE_FAILURE,
  FETCH_ATTENDANCE_OVERVIEW_DETAIL_SUCCESS,
  FETCH_ATTENDANCE_OVERVIEW_DETAIL_FAILURE,
} from "./actions";

const initialState = {
  data: [],
  overview: [],
  detailData: [],
  error: null,
};

const attendanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ATTENDANCE_SUCCESS:
      return { ...state, data: action.payload, error: null };

    case FETCH_ATTENDANCE_FAILURE:
      return { ...state, error: action.payload };

    case FETCH_ATTENDANCE_OVERVIEW_SUCCESS:
      return { ...state, overview: action.payload, error: null };

    case FETCH_ATTENDANCE_OVERVIEW_FAILURE:
      return { ...state, error: action.payload };

    case FETCH_ATTENDANCE_OVERVIEW_DETAIL_SUCCESS:
      return { ...state, detailData: action.payload, error: null };

    case FETCH_ATTENDANCE_OVERVIEW_DETAIL_FAILURE:
      return { ...state, error: action.payload };

    case ADD_ATTENDANCE_SUCCESS:
      return { ...state, data: [...state.data, action.payload], error: null };

    case ADD_ATTENDANCE_FAILURE:
      return { ...state, error: action.payload };

    case EDIT_ATTENDANCE_SUCCESS:
      return {
        ...state,
        data: state.data.map(attendance =>
          attendance.id === action.payload.id ? action.payload : attendance
        ),
        error: null,
      };

    case EDIT_ATTENDANCE_FAILURE:
      return { ...state, error: action.payload };

    case DELETE_ATTENDANCE_SUCCESS:
      return {
        ...state,
        data: state.data.filter(attendance => attendance.id !== action.payload.attendanceId),
        error: null,
      };

    case DELETE_ATTENDANCE_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default attendanceReducer;
