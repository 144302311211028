import {
  ADD_OFFICE_REQUEST,
  ADD_OFFICE_SUCCESS,
  ADD_OFFICE_FAILURE,
  UPDATE_OFFICE_REQUEST,
  UPDATE_OFFICE_SUCCESS,
  UPDATE_OFFICE_FAILURE,
  FETCH_OFFICES_REQUEST,
  FETCH_OFFICES_SUCCESS,
  FETCH_OFFICES_FAILURE,
  DELETE_OFFICE_REQUEST,
  DELETE_OFFICE_SUCCESS,
  DELETE_OFFICE_FAILURE,
} from './actions';

const initialState = {
  success: false,
  error: null,
  data: [],
  loading: false,
};

const officeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_OFFICE_REQUEST:
      return {
        ...state,
        success: false,
        error: null,
        loading: true,
      };
    case ADD_OFFICE_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        data: [...state.data, action.payload],
      };
    case ADD_OFFICE_FAILURE:
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload,
      };

    case UPDATE_OFFICE_REQUEST:
      return {
        ...state,
        success: false,
        error: null,
        loading: true,
      };
    case UPDATE_OFFICE_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        data: state.data.map((office) =>
          office.id === action.payload.id ? action.payload : office
        ),
      };
    case UPDATE_OFFICE_FAILURE:
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload,
      };

    case FETCH_OFFICES_REQUEST:
      return {
        ...state,
        success: false,
        error: null,
        loading: true,
      };
    case FETCH_OFFICES_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        data: action.payload,
      };
    case FETCH_OFFICES_FAILURE:
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload,
      };

    case DELETE_OFFICE_REQUEST:
      return {
        ...state,
        success: false,
        error: null,
        loading: true,
      };
    case DELETE_OFFICE_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        data: state.data.filter((office) => office.id !== action.payload),
      };
    case DELETE_OFFICE_FAILURE:
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default officeReducer;
