import {
  ADD_EMPLOYEE_REQUEST,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_FAILURE,
  EDIT_EMPLOYEE_REQUEST,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_SUCCESS,
} from './webActions';

const initialState = {
  success: false,
  error: null,
  data: [],
};

const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
      case ADD_EMPLOYEE_REQUEST:
          return {
              ...state,
              success: false,
              error: null,
          };
      case ADD_EMPLOYEE_SUCCESS:
          return {
              ...state,
              success: true,
              data: [...state.data, action.payload],
          };
      case ADD_EMPLOYEE_FAILURE:
          return {
              ...state,
              success: false,
              error: action.payload,
          };
      case EDIT_EMPLOYEE_REQUEST:
          return {
              ...state,
              success: false,
              error: null,
          };
      case EDIT_EMPLOYEE_SUCCESS:
          return {
              ...state,
              success: true,
              data: state.data.map((employee) =>
                  employee.id === action.payload.id ? action.payload : employee
              ),
          };
      case EDIT_EMPLOYEE_FAILURE:
          return {
              ...state,
              success: false,
              error: action.payload,
          };
          case DELETE_EMPLOYEE_SUCCESS:
            return {
              ...state,
              data: state.data.filter(employee => employee.id !== action.payload),
            };
      default:
          return state;
  }
};

export default employeeReducer;
