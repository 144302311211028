import { call, put, takeLatest } from "redux-saga/effects";
import {
  POST_REGULARIZE_REQUEST,
  postRegularizeSuccess,
  postRegularizeFailure,
  FETCH_REGULARIZE_REQUEST,
  fetchRegularizeSuccess,
  fetchRegularizeFailure,
} from "./actions";

// Assuming token and baseUrl are defined or passed in
import config from "../../config";
import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

function* postRegularizeSaga(action) {
  try {
    const response = yield call(
      fetch,
      `${baseUrl}/auth/add/user/regularization`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (!response.ok) {
      // Handle non-200 HTTP status codes as errors
      yield put(postRegularizeFailure(data.message || "An error occurred"));
    } else {
      yield put(postRegularizeSuccess(data));
    }
  } catch (error) {
    yield put(postRegularizeFailure(error.message || "Network error"));
  }
}

function* fetchRegularizeSaga() {
    let userIds;
    let userRole;
    const AUTH_SESSION_KEY = "userInfo";
    const userDataString = sessionStorage.getItem(AUTH_SESSION_KEY);
    if (userDataString) {
      try {
        const parsedUser = JSON.parse(userDataString);
        if (parsedUser && parsedUser.data && parsedUser.data.userInfo) {
          userRole = parsedUser.data.userInfo;
          userIds = userRole.id;
          console.log("userid", userIds)
        } else {
          console.error("userInfo is undefined in user data.");
        }
      } catch (error) {
        console.error("Failed to parse user data from sessionStorage:", error);
      }
    } else {
      console.log("No user data found in sessionStorage.");
    }
    
  try {
    const response = yield call(
      fetch,
      `${baseUrl}/auth/get/user/regularization-list/userId/${userIds}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (!response.ok) {
      // Handle non-200 HTTP status codes as errors
      yield put(fetchRegularizeFailure(data.message || "An error occurred"));
    } else {
      yield put(fetchRegularizeSuccess(data));
    }
  } catch (error) {
    yield put(fetchRegularizeFailure(error.message || "Network error"));
  }
}
export function* watchRegularizeRequest() {
  yield takeLatest(POST_REGULARIZE_REQUEST, postRegularizeSaga);
  yield takeLatest(FETCH_REGULARIZE_REQUEST, fetchRegularizeSaga);
}
