
import { call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_USER_ATTENDANCE_REQUEST, fetchUserAttendanceSuccess, fetchUserAttendanceFailure } from "./actions";
import { APICore } from "../../helpers/api/apiCore";
import config from '../../config';

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

function* fetchUserAttendanceSaga(action) {
  try {
    const { userId, month, year } = action.payload;

    const response = yield call(fetch, `https://tys-hrms-app.onrender.com/auth/get/user/attendance/${userId.userId}?month=${userId.month}&year=${userId.year}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = yield response.json();
    yield put(fetchUserAttendanceSuccess(data));
  } catch (error) {
    yield put(fetchUserAttendanceFailure(error.message));
  }
}

export function* watchUserFetchAttendance() {
  yield takeLatest(FETCH_USER_ATTENDANCE_REQUEST, fetchUserAttendanceSaga);
}

