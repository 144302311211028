import {
  ADD_EDUCATION_REQUEST,
  ADD_EDUCATION_SUCCESS,
  ADD_EDUCATION_FAILURE,
  EDIT_EDUCATION_REQUEST,
  EDIT_EDUCATION_SUCCESS,
  EDIT_EDUCATION_FAILURE,
  DELETE_EDUCATION_SUCCESS,
  FETCH_EDUCATION_REQUEST,
  FETCH_EDUCATION_SUCCESS,
  FETCH_EDUCATION_FAILURE,
} from "./actions";

const initialState = {
  success: false,
  error: null,
  data: [],
};

const educationReducer = (state = initialState, action) => {
  switch (action.type) {
    // add
    case ADD_EDUCATION_REQUEST:
      return {
        ...state,
        success: false,
        error: null,
      };
    case ADD_EDUCATION_SUCCESS:
      return {
        ...state,
        success: true,
        data: [...state.data, action.payload],
      };
    case ADD_EDUCATION_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
      };
      case FETCH_EDUCATION_REQUEST:
        return {
          ...state,
          success: false,
          error: null, // Clear any previous error
        };
      
      case FETCH_EDUCATION_SUCCESS:
        return {
          ...state,
        loading: false,
        success: true,
        error: null,           // No error on success
        data: action.payload,
        };
      
      case FETCH_EDUCATION_FAILURE:
        return {
          ...state,
          success: false,
          error: action.payload, // Set the error message
        };
      
    //   EDIT
    case EDIT_EDUCATION_REQUEST:
      return {
        ...state,
        success: false,
        error: null,
      };
    case EDIT_EDUCATION_SUCCESS:
      return {
        ...state,
        success: true,
        data: state.data.map((education) =>
          education.id === action.payload.id ? action.payload : education
        ),
      };
    case EDIT_EDUCATION_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
      };


    //   Delete
    case DELETE_EDUCATION_SUCCESS:
      return {
        ...state,
        // data: state.data.filter((education) => education.id !== action.payload),
        data: state.data.filter(item => item.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export default educationReducer;
