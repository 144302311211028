import { call, put, takeLatest } from 'redux-saga/effects';
import { 
  ADD_OFFICE_REQUEST,
  addOfficeSuccess, 
  addOfficeFailure,
  UPDATE_OFFICE_REQUEST,
  updateOfficeSuccess,
  updateOfficeFailure,
  FETCH_OFFICES_REQUEST,
  fetchOfficesSuccess,
  fetchOfficesFailure,
  DELETE_OFFICE_REQUEST,
  deleteOfficeSuccess,
  deleteOfficeFailure,
} from './actions';
import config from '../../config';
import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

// Add Office Saga
function* addOfficeSaga(action) {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/add/office/location`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    
    if (!response.ok) {
      yield put(addOfficeFailure(data.message));
    } else {
      yield put(addOfficeSuccess(data));
    }
  } catch (error) {
    yield put(addOfficeFailure(error.message));
  }
}

// Update Office Saga
function* updateOfficeSaga(action) {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/update/office/location/${action.payload.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();

    if (!response.ok) {
      yield put(updateOfficeFailure(data.message));
    } else {
      yield put(updateOfficeSuccess(data));
    }
  } catch (error) {
    yield put(updateOfficeFailure(error.message));
  }
}

// Fetch All Offices Saga
function* fetchOfficesSaga(action) {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/get/all-office-location/list`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const data = yield response.json();

    if (!response.ok) {
      yield put(fetchOfficesFailure(data.message));
    } else {
      yield put(fetchOfficesSuccess(data));
    }
  } catch (error) {
    yield put(fetchOfficesFailure(error.message));
  }
}

// Delete Office Saga
function* deleteOfficeSaga(action) {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/delete/office/location/${action.payload}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.ok) {
      yield put(deleteOfficeSuccess(action.payload));
    } else {
      const data = yield response.json();
      yield put(deleteOfficeFailure(data.message));
    }
  } catch (error) {
    yield put(deleteOfficeFailure(error.message));
  }
}

// Watcher function
export function* watchOfficeActions() {
  yield takeLatest(ADD_OFFICE_REQUEST, addOfficeSaga);
  yield takeLatest(UPDATE_OFFICE_REQUEST, updateOfficeSaga);
  yield takeLatest(FETCH_OFFICES_REQUEST, fetchOfficesSaga);
  yield takeLatest(DELETE_OFFICE_REQUEST, deleteOfficeSaga);
}
