import jwtDecode from "jwt-decode";
import axios from "axios";

import config from "../../config";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.API_URL;

// intercepting to capture errors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error("Interceptor error:", error); // Log the full error object

    let message;

    if (error.response) {
      // Log the entire response for debugging
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);

      // Extract specific error message
      message = error.response.data?.message || error.message;
    } else {
      message = error.message || "Network error";
    }

    // Optionally log the URL that caused the error
    console.error("Request URL:", error.config.url);

    return Promise.reject(message);
  }
);



const AUTH_SESSION_KEY = "users";

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  if (token) axios.defaults.headers.common["Authorization"] = "JWT " + token;
  else delete axios.defaults.headers.common["Authorization"];
};

const getUserFromCookie = () => {
  const user = sessionStorage.getItem(AUTH_SESSION_KEY);
  if (!user) return null;

  try {
    const parsedUser = JSON.parse(user);
    return parsedUser;
  } catch (error) {
    console.error("Error parsing user from session:", error);
    return null;
  }
};
class APICore {
  /**
   * Fetches data from given url
   */
  get = (url: string, params: any) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }
    return response;
  };

  getFile = (url: string, params: any) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, { responseType: "blob" });
    } else {
      response = axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls: string, params: any) => {
    const reqs = [];
    let queryString = "";
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * post given data to url
   */
  create = async (url: string, data: any) => {
    console.log("POST request to:", url, "with data:", data);
    try {
      const response = await axios.post(url, data);
      console.log("Response from create method:", response,url, data);
      return response;
    } catch (error) {
      console.error("Create method error:", error,url,data);
      throw error; // Let the interceptor handle this
    }
  };
  

  /**
   * Updates patch data
   */
  updatePatch = (url: string, data: any) => {
    return axios.patch(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data: any) => {
    return axios.put(url, data); // Correctly uses PUT method
  };

  /**
   * Deletes data
   */
  delete = (url: string) => {
    return axios.delete(url);
  };

  /**
   * post given data to url with file
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData, config);
  };

  /**
   * post given data to url with file
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.patch(url, formData, config);
  };

  isUserAuthenticated = () => {
    const user = this.getLoggedInUser();
  
    if (!user) {
      return false;
    }
  
    const token = user.token; // Correctly access the token here
    try {
      const decoded: any = jwtDecode(token.token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        console.warn("access token expired");
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error("Error decoding token:", error); // Log the error
      return false; // Return false if decoding fails
    }
  };
  

  setLoggedInUser = (session: any) => {
    if (session) {
      const { token, userInfo } = session;
      sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify({ token, ...userInfo }));
    } else {
      sessionStorage.removeItem(AUTH_SESSION_KEY);
    }
  };
  
  /**
   * Returns the logged in user
   */
  getLoggedInUser = () => {
    return getUserFromCookie();
  };

  setUserInSession = (modifiedUser: any) => {
    let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
    if (userInfo) {
      const { token, user } = JSON.parse(userInfo);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };
}

/*
Check if token available in session
*/
let user = getUserFromCookie();
if (user) {
  const { token } = user;
  if (token) {
    setAuthorization(token);
  }
}

export { APICore, setAuthorization };
