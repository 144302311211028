import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_ALL_LEAVE_REQUEST,
  fetchAllLeaveSuccess,
  fetchAllLeaveFailure,
  DELETE_ALL_LEAVE_REQUEST,
  deleteLeaveSuccess,
  deleteLeaveFailure,
  UPDATE_ALL_LEAVE_STATUS_REQUEST,
  updateLeaveStatusSuccess,
  updateLeaveStatusFailure,
} from './actions';
import config from '../../config';
import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

function* fetchAllLeaveSaga() {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/get/all-users/leave/list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const data = yield response.json();
    yield put(fetchAllLeaveSuccess(data));
  } catch (error) {
    yield put(fetchAllLeaveFailure(error.message));
  }
}

function* deleteLeaveSaga(action) {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/delete/user/leave-request/${action.payload}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    yield put(deleteLeaveSuccess(action.payload));
  } catch (error) {
    yield put(deleteLeaveFailure(error.message));
  }
}

function* updateLeaveStatusSaga(action) {
  try {
    console.log('Received Action:', action.payload.payload);
    const { leave_id, user_id, start_date, end_date, type } = action.payload.payload;
    const formattedPayload = {
      leave_id,
      user_id,
      start_date,
      end_date,
      type,
    };
    const response = yield call(fetch, `${baseUrl}/admin/update/leave/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(formattedPayload),
    });
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    yield put(updateLeaveStatusSuccess(formattedPayload));
  } catch (error) {
    yield put(updateLeaveStatusFailure(error.message));
  }
}


export function* watchLeave() {
  yield takeLatest(FETCH_ALL_LEAVE_REQUEST, fetchAllLeaveSaga);
  yield takeLatest(DELETE_ALL_LEAVE_REQUEST, deleteLeaveSaga);
  yield takeLatest(UPDATE_ALL_LEAVE_STATUS_REQUEST, updateLeaveStatusSaga);
}
