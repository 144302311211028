import { ADD_HOLIDAY_FAILURE, ADD_HOLIDAY_REQUEST, ADD_HOLIDAY_SUCCESS, DELETE_HOLIDAY_FAILURE, DELETE_HOLIDAY_REQUEST, DELETE_HOLIDAY_SUCCESS, FETCH_HOLIDAYS_FAILURE, FETCH_HOLIDAYS_REQUEST, FETCH_HOLIDAYS_SUCCESS, UPDATE_HOLIDAY_FAILURE, UPDATE_HOLIDAY_REQUEST, UPDATE_HOLIDAY_SUCCESS } from "./actions";

const initialState = {
    success: false,
    error: null,
    data: [],
    loading: false,
  };
  
  const holidayReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_HOLIDAY_REQUEST:
      case UPDATE_HOLIDAY_REQUEST:
      case FETCH_HOLIDAYS_REQUEST:
      case DELETE_HOLIDAY_REQUEST:
        return {
          ...state,
          success: false,
          error: null,
          loading: true,
        };
      case ADD_HOLIDAY_SUCCESS:
        return {
          ...state,
          success: true,
          loading: false,
          data: [...state.data, action.payload],
        };
      case ADD_HOLIDAY_FAILURE:
      case UPDATE_HOLIDAY_FAILURE:
      case FETCH_HOLIDAYS_FAILURE:
      case DELETE_HOLIDAY_FAILURE:
        return {
          ...state,
          success: false,
          loading: false,
          error: action.payload,
        };
      case UPDATE_HOLIDAY_SUCCESS:
        return {
          ...state,
          success: true,
          loading: false,
          data: state.data.map((holiday) =>
            holiday.id === action.payload.id ? action.payload : holiday
          ),
        };
      case FETCH_HOLIDAYS_SUCCESS:
        return {
          ...state,
          success: true,
          loading: false,
          data: action.payload,
        };
      case DELETE_HOLIDAY_SUCCESS:
        return {
          ...state,
          success: true,
          loading: false,
          data: state.data.filter((holiday) => holiday.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default holidayReducer;
  