import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_PROJECT_REQUEST,
  addProjectSuccess,
  addProjectFailure,
  EDIT_PROJECT_REQUEST,
  editProjectSuccess,
  editProjectFailure,
  DELETE_PROJECT_REQUEST,
  deleteProjectSuccess,
  deleteProjectFailure,
  FETCH_PROJECT_REQUEST,
  fetchProjectSuccess,
  fetchProjectFailure,
} from "./actions";
import config from "../../config";
import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

function* fetchProjectSaga(action) {
  try {
    // Check if token is defined
    if (!token) {
      throw new Error("Token is undefined");
    }

    // const url = `${baseUrl}/auth/get/user/PROJECT-details/${action.payload.id}?status=${action.payload.status}`;
    const url = `${baseUrl}/auth/get/user/project/list/${action.payload.id}`;
    // const url = `${baseUrl}/auth/get/all-users/project/list`;
    // console.log("Fetching from URL:", url);

    // Perform fetch
    const response = yield call(fetch, url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("Fetch response received:", response);

    // Check if response is ok, otherwise throw error
    if (!response.ok) {
      const errorData = yield response.json();
      console.error("Fetch failed with error:", errorData);
      yield put(
        fetchProjectFailure(
          errorData.message || "Failed to fetch PROJECT details"
        )
      );
      return;
    }

    // Parse JSON response
    let data;
    try {
      data = yield response.json();
      // console.log("Fetched data:", data);
    } catch (jsonError) {
      console.error("Error parsing JSON:", jsonError);
      throw new Error("Invalid JSON response from server");
    }

    // Dispatch success action
    yield put(fetchProjectSuccess(data));
  } catch (error) {
    console.error("Error in fetchPROJECTSaga:", error);
    yield put(fetchProjectFailure(error.message || "An error occurred"));
  }
}

function* addProjectSaga(action) {
  try {
    const response = yield call(
      fetch,
      `${baseUrl}/auth/add/user/project-details`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (!response.ok) {
      yield put(addProjectFailure(data.message));
    } else {
      yield put(addProjectSuccess(data));
    }
  } catch (error) {
    yield put(addProjectFailure(error.message));
  }
}

// function* editProjectSaga(action) {
//   console.log("Starting editPROJECTSaga with action:", action);
//   try {
//     const response = yield call(
//       fetch,
//       `${baseUrl}/auth/update/user/project-details/${action.payload.id}`,
//       {
//         method: "PUT",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify(action.payload),
//       }
//     );

//     const data = yield response.json();
//     console.log("ediprotsaga", data)

//     if (!response.ok) {
//       yield put(editProjectFailure(data.message));
//     } else {
//       yield put(editProjectSuccess(data));
//     }
//   } catch (error) {
//     yield put(editProjectFailure(error.message));
//   }
// }

function* editProjectSaga(action) {
  try {
    const response = yield call(
      fetch,
      `${baseUrl}/auth/update/user/project-details/${action.payload.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(action.payload),
      }
    );

    
    const contentType = response.headers.get("Content-Type");

    if (!response.ok) {
    
      let errorData;
      if (contentType && contentType.includes("application/json")) {
        errorData = yield response.json();
      } else {
        errorData = { message: "Server responded with an unexpected format" };
        console.error("Server responded with HTML or non-JSON content:", errorData);
      }
      yield put(editProjectFailure(errorData.message || "Failed to edit project details"));
      return;
    }

    
    let data;
    if (contentType && contentType.includes("application/json")) {
      data = yield response.json();
      console.log("Received JSON response:", data);
      yield put(editProjectSuccess(data));
    } else {
      console.error("Expected JSON, but received unexpected response format");
      yield put(editProjectFailure("Unexpected response format from server"));
    }

  } catch (error) {
    console.error("Caught an error in editProjectSaga:", error);
    yield put(editProjectFailure(error.message || "An unexpected error occurred"));
  }
}



function* deleteProjectSaga(action) {
  try {
    
    const { id } = action.payload;
    console.log("deletedu", id);

    const response = yield call(
      fetch,
      `${baseUrl}/auth/delete/user/project-details/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`, // Ensure the token is correctly accessed
        },
      }
    );

    if (response.ok) {
      
      yield put(deleteProjectSuccess(id));
    } else {
      
      const data = yield response.json(); // Some error message from the backend
      yield put(
        deleteProjectFailure(
          data.message || "Failed to delete PROJECT record."
        )
      );
    }
  } catch (error) {
    
    yield put(
      deleteProjectFailure(error.message || "An unexpected error occurred.")
    );
  }
}

export function* watchProjectActions() {
  yield takeLatest(FETCH_PROJECT_REQUEST, fetchProjectSaga);
  yield takeLatest(ADD_PROJECT_REQUEST, addProjectSaga);
  yield takeLatest(EDIT_PROJECT_REQUEST, editProjectSaga);
  yield takeLatest(DELETE_PROJECT_REQUEST, deleteProjectSaga);
}
