import {
    POST_REIMBURSEMENT_REQUEST,
    POST_REIMBURSEMENT_SUCCESS,
    POST_REIMBURSEMENT_FAILURE,
    FETCH_REIMBURSEMENT_REQUEST,
    FETCH_REIMBURSEMENT_SUCCESS,
    FETCH_REIMBURSEMENT_FAILURE,
  } from "./actions";
  
  const initialState = {
    loading: false,
    data: [],
    error: null,
  };
  
  const userReimbursementReducer = (state = initialState, action) => {
    switch (action.type) {
      case POST_REIMBURSEMENT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case POST_REIMBURSEMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.response,
        };
      case POST_REIMBURSEMENT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      case FETCH_REIMBURSEMENT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_REIMBURSEMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.data,
        };
      case FETCH_REIMBURSEMENT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
  
      default:
        return state;
    }
  };
  
  export default userReimbursementReducer;
  