export const FETCH_REGULARIZATION_REQUEST = 'FETCH_REGULARIZATION_REQUEST';
export const FETCH_REGULARIZATION_SUCCESS = 'FETCH_REGULARIZATION_SUCCESS';
export const FETCH_REGULARIZATION_FAILURE = 'FETCH_REGULARIZATION_FAILURE';

export const DELETE_REGULARIZATION_REQUEST = 'DELETE_REGULARIZATION_REQUEST';
export const DELETE_REGULARIZATION_SUCCESS = 'DELETE_REGULARIZATION_SUCCESS';
export const DELETE_REGULARIZATION_FAILURE = 'DELETE_REGULARIZATION_FAILURE';

export const UPDATE_REGULARIZATION_STATUS_REQUEST = 'UPDATE_REGULARIZATION_STATUS_REQUEST';
export const UPDATE_REGULARIZATION_STATUS_SUCCESS = 'UPDATE_REGULARIZATION_STATUS_SUCCESS';
export const UPDATE_REGULARIZATION_STATUS_FAILURE = 'UPDATE_REGULARIZATION_STATUS_FAILURE';

export const fetchRegularizationRequest = () => ({
  type: FETCH_REGULARIZATION_REQUEST,
});

export const fetchRegularizationSuccess = (data) => ({
  type: FETCH_REGULARIZATION_SUCCESS,
  payload: data,
});

export const fetchRegularizationFailure = (error) => ({
  type: FETCH_REGULARIZATION_FAILURE,
  payload: error,
});

export const deleteRegularizationRequest = (id) => ({
  type: DELETE_REGULARIZATION_REQUEST,
  payload: id,
});

export const deleteRegularizationSuccess = (id) => ({
  type: DELETE_REGULARIZATION_SUCCESS,
  payload: id,
});

export const deleteRegularizationFailure = (error) => ({
  type: DELETE_REGULARIZATION_FAILURE,
  payload: error,
});

export const updateRegularizationStatusRequest = (id, type) => ({
  type: UPDATE_REGULARIZATION_STATUS_REQUEST,
  payload: {  att_id: id, type },
});

export const updateRegularizationStatusSuccess = (id, type) => ({
  type: UPDATE_REGULARIZATION_STATUS_SUCCESS,
  payload: {  att_id: id, type },
});

export const updateRegularizationStatusFailure = (error) => ({
  type: UPDATE_REGULARIZATION_STATUS_FAILURE,
  payload: error,
});
