import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_PROFESSIONAL_DETAILS_REQUEST,
  fetchProfessionalDetailsSuccess,
  fetchProfessionalDetailsFailure,
} from "./actions";
import {
  UPDATE_PROFESSIONAL_DETAILS_REQUEST,
  updateProfessionalDetailsSuccess,
  updateProfessionalDetailsFailure,
} from "./actions";

function* fetchProfessionalDetailsSaga(action) {
  const { userId, token } = action.payload;
  // console.log(userId, token);

  try {
    const response = yield call(
      fetch,
      `https://tys-hrms-app.onrender.com/auth/get/user/professional-details/${userId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // console.log("API Response Status:", response.status);

    if (response.ok) {
      const data = yield response.json();

      // console.log("API Data:", data); // Log data to ensure it's received
      yield put(fetchProfessionalDetailsSuccess(data));
    } else {
      const errorMessage = yield response.text();
      console.error("API Error:", errorMessage); // Log error message
      yield put(fetchProfessionalDetailsFailure(errorMessage));
    }
  } catch (error) {
    console.error("Request Error:", error); // Catch and log any request errors
    yield put(fetchProfessionalDetailsFailure(error.message));
  }
}



function* updateProfessionalDetailsSaga(action) {
  const { userId, token, updatedData } = action.payload;

  // console.log("Token:", token);
  console.log("Updated Data:", updatedData);

  if (!userId) {
    console.error("userId is undefined");
    return; // Exit if userId is not defined
  }

  try {
    const response = yield call(
      fetch,
      `https://tys-hrms-app.onrender.com/auth/update/user/professional-details/${userId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      }
    );

    console.log("Response Status:", response.status);

    if (response.ok) {
      const data = yield response.json();
      // console.log("Data returned from API:", data); // Log the exact response data

      yield put(updateProfessionalDetailsSuccess(data));
    } else {
      const errorMessage = yield response.text(); // Log the error response body
      console.error("Error Message from API:", errorMessage);

      yield put(updateProfessionalDetailsFailure(errorMessage));
    }
  } catch (error) {
    console.error("Error in Saga:", error.message); // Log the error if the fetch fails
    yield put(updateProfessionalDetailsFailure(error.message));
  }
}

export function* watchFetchProfessionalDetails() {
  yield takeLatest(
    FETCH_PROFESSIONAL_DETAILS_REQUEST,
    fetchProfessionalDetailsSaga
  );
}
export function* watchUpdateProfessionalDetails() {
  yield takeLatest(
    UPDATE_PROFESSIONAL_DETAILS_REQUEST,
    updateProfessionalDetailsSaga
  );
}

// function* updateProfessionalDetailsSaga(action) {
//   const { userId, token, updatedData } = action.payload;
//   console.log("tols", token , updatedData)
//   if (!userId) {
//     console.error("userId is undefined");
//     return; // exit if userId is not defined
//   }
//   try {
//     const response = yield call(fetch, `https://tyssss-hrms-app.onrender.com/auth/update/user/professional-details/${userId}`, {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(updatedData),

//     });

//     if (response.ok) {
//       const data = yield response.json();
//       console.log("data", data)
//       yield put(updateProfessionalDetailsSuccess(data));
//     } else {
//       const errorMessage = yield response.text();
//       yield put(updateProfessionalDetailsFailure(errorMessage));
//     }
//   } catch (error) {
//     yield put(updateProfessionalDetailsFailure(error.message));
//   }
// }

// export function* watchUpdateProfessionalDetails() {
//   yield takeLatest(UPDATE_PROFESSIONAL_DETAILS_REQUEST, updateProfessionalDetailsSaga);
// }
