import {
    FETCH_USER_DAILY_ATTENDANCE_SUCCESS,
    FETCH_USER_DAILY_ATTENDANCE_FAILURE,
    FETCH_USER_DAILY_ATTENDANCE_REQUEST,
    TOGGLE_CHECK_IN_OUT_REQUEST,
    TOGGLE_CHECK_IN_OUT_SUCCESS,
    TOGGLE_CHECK_IN_OUT_FAILURE
  } from "./actions";
  


const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,  // Add success flag here
};

const attendanceUserDailyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_DAILY_ATTENDANCE_REQUEST:
    case TOGGLE_CHECK_IN_OUT_REQUEST:
      return { ...state, loading: true, error: null, success: false };

    case FETCH_USER_DAILY_ATTENDANCE_SUCCESS:
      return { ...state, loading: false, data: action.payload, success: true };

    case FETCH_USER_DAILY_ATTENDANCE_FAILURE:
    case TOGGLE_CHECK_IN_OUT_FAILURE:
      return { ...state, loading: false, error: action.payload, success: false };

    case TOGGLE_CHECK_IN_OUT_SUCCESS:
      return { ...state, loading: false, data: action.payload, success: true };  // Update success flag on success

    default:
      return state;
  }
};



export default attendanceUserDailyReducer;