// Action Types
export const FETCH_ALL_LEAVE_REQUEST = 'FETCH_ALL_LEAVE_REQUEST';
export const FETCH_ALL_LEAVE_SUCCESS = 'FETCH_ALL_LEAVE_SUCCESS';
export const FETCH_ALL_LEAVE_FAILURE = 'FETCH_ALL_LEAVE_FAILURE';

export const DELETE_ALL_LEAVE_REQUEST = 'DELETE_ALL_LEAVE_REQUEST';
export const DELETE_ALL_LEAVE_SUCCESS = 'DELETE_ALL_LEAVE_SUCCESS';
export const DELETE_ALL_LEAVE_FAILURE = 'DELETE_ALL_LEAVE_FAILURE';

export const UPDATE_ALL_LEAVE_STATUS_REQUEST = 'UPDATE_ALL_LEAVE_STATUS_REQUEST';
export const UPDATE_ALL_LEAVE_STATUS_SUCCESS = 'UPDATE_ALL_LEAVE_STATUS_SUCCESS';
export const UPDATE_ALL_LEAVE_STATUS_FAILURE = 'UPDATE_ALL_LEAVE_STATUS_FAILURE';

// Action Creators
export const fetchAllLeaveRequest = () => ({
  type: FETCH_ALL_LEAVE_REQUEST,
});

export const fetchAllLeaveSuccess = (data) => ({
  type: FETCH_ALL_LEAVE_SUCCESS,
  payload: data,
});

export const fetchAllLeaveFailure = (error) => ({
  type: FETCH_ALL_LEAVE_FAILURE,
  payload: error,
});

export const deleteLeaveRequest = (id) => ({
  type: DELETE_ALL_LEAVE_REQUEST,
  payload: id,
});

export const deleteLeaveSuccess = (id) => ({
  type: DELETE_ALL_LEAVE_SUCCESS,
  payload: id,
});

export const deleteLeaveFailure = (error) => ({
  type: DELETE_ALL_LEAVE_FAILURE,
  payload: error,
});

export const updateLeaveStatusRequest = (payload) => ({
  type: UPDATE_ALL_LEAVE_STATUS_REQUEST,
  payload: { payload },
});

export const updateLeaveStatusSuccess = (leave_id, status) => ({
  type: UPDATE_ALL_LEAVE_STATUS_SUCCESS,
  payload: { leave_id, status },
});


export const updateLeaveStatusFailure = (error) => ({
  type: UPDATE_ALL_LEAVE_STATUS_FAILURE,
  payload: error,
});
