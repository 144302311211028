import {
    GET_USER_ROLE_REQUEST,
    GET_USER_ROLE_SUCCESS,
    GET_USER_ROLE_FAILURE,
    ADD_USER_ROLE_REQUEST,
    ADD_USER_ROLE_SUCCESS,
    ADD_USER_ROLE_FAILURE,
    ASSIGN_ROLE_REQUEST,
    ASSIGN_ROLE_SUCCESS,
    ASSIGN_ROLE_FAILURE,
} from './actions';

const initialRoleState = {
    success: false,
    error: null,
    data: [],
};

const userRoleReducer = (state = initialRoleState, action) => {
    switch (action.type) {
        case GET_USER_ROLE_REQUEST:
            return {
                ...state,
                success: false,
                error: null,
            };
        case GET_USER_ROLE_SUCCESS:
            return {
                ...state,
                success: true,
                data: action.payload,
            };
        case GET_USER_ROLE_FAILURE:
            return {
                ...state,
                success: false,
                error: action.payload,
            };
        case ADD_USER_ROLE_REQUEST:
            return {
                ...state,
                success: false,
                error: null,
            };
        case ADD_USER_ROLE_SUCCESS:
            return {
                ...state,
                success: true,
                data: [...state.data, action.payload],
            };
        case ADD_USER_ROLE_FAILURE:
            return {
                ...state,
                success: false,
                error: action.payload,
            };
        case ASSIGN_ROLE_REQUEST:
            return {
                ...state,
                success: false,
                error: null,
            };
        case ASSIGN_ROLE_SUCCESS:
            return {
                ...state,
                success: true,
                data: [...state.data, action.payload],
            };
        case ASSIGN_ROLE_FAILURE:
            return {
                ...state,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default userRoleReducer;
