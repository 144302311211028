import {
    FETCH_USER_ATTENDANCE_SUCCESS,
    FETCH_USER_ATTENDANCE_FAILURE,
} from "./actions";


const initialState = {
    data: [],
    error: null,
    loading: false,  // Add loading state
};

const userAttendanceReducer = (state = initialState, action) => {
    switch(action.type) {
        // case FETCH_USER_ATTENDANCE_REQUEST:
            // return { ...state, loading: true };  // Set loading to true when request starts
        
        case FETCH_USER_ATTENDANCE_SUCCESS:
            return { ...state, data: action.payload, error: null, loading: false };
        
        case FETCH_USER_ATTENDANCE_FAILURE:
            return { ...state, error: action.payload, loading: false };
            
        default:
            return state;    
    }
};

export default userAttendanceReducer;

