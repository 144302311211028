// persionaldetails/reducers.js
import {
    FETCH_BANK_DETAILS_SUCCESS,
    FETCH_BANK_DETAILS_FAILURE, 
    UPDATE_BANK_DETAILS_SUCCESS,
    UPDATE_BANK_DETAILS_FAILURE,
  } from './actions';
  
  const initialState = {
    data: [],
    loading: false,
    error: null,
    updateStatus: [],
  };
  
  const bankDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_BANK_DETAILS_SUCCESS:
        return { ...state, loading: false, data: action.payload };
      case FETCH_BANK_DETAILS_FAILURE:
        return { ...state, loading: false, error: action.payload };
  
      // Update actions
      case UPDATE_BANK_DETAILS_SUCCESS:
        return { ...state, loading: false, updateStatus: 'success', data: action.payload };
      case UPDATE_BANK_DETAILS_FAILURE:
        return { ...state, loading: false, updateStatus: 'failure', error: action.payload };  
      default:
        return state;
    }
  };
  
  export default bankDetailsReducer;
  