import { call, put, takeLatest } from "redux-saga/effects";
import {
  POST_REIMBURSEMENT_REQUEST,
  postReimbursementSuccess,
  postReimbursementFailure,
  FETCH_REIMBURSEMENT_REQUEST,
  fetchReimbursementSuccess,
  fetchReimbursementFailure,
} from "./actions";

// Assuming token and baseUrl are defined or passed in
import config from "../../config";
import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

function* postReimbursementSaga(action) {

    console.log("addreimb",action.payload)
  try {
    const response = yield call(
      fetch,
      `${baseUrl}/auth/add/user/reimbursement`, // Updated the URL
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (!response.ok) {
      // Handle non-200 HTTP status codes as errors
      yield put(postReimbursementFailure(data.message || "An error occurred"));
    //   console.log("reimv error", data.message)
    } else {
      yield put(postReimbursementSuccess(data));
    }
  } catch (error) {
    yield put(postReimbursementFailure(error.message || "Network error"));
  }
}

function* fetchReimbursementSaga() {
  let userIds;
  let userRole;
  const AUTH_SESSION_KEY = "userInfo";
  const userDataString = sessionStorage.getItem(AUTH_SESSION_KEY);
  if (userDataString) {
    try {
      const parsedUser = JSON.parse(userDataString);
      if (parsedUser && parsedUser.data && parsedUser.data.userInfo) {
        userRole = parsedUser.data.userInfo;
        userIds = userRole.id;
        console.log("userid", userIds);
      } else {
        console.error("userInfo is undefined in user data.");
      }
    } catch (error) {
      console.error("Failed to parse user data from sessionStorage:", error);
    }
  } else {
    console.log("No user data found in sessionStorage.");
  }

  try {
    const response = yield call(
      fetch,
      `${baseUrl}/auth/get/reimbursements/userId/${userIds}`, // Updated the URL
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = yield response.json();

    if (!response.ok) {
      // Handle non-200 HTTP status codes as errors
      yield put(fetchReimbursementFailure(data.message || "An error occurred"));
    } else {
      yield put(fetchReimbursementSuccess(data));
    }
  } catch (error) {
    yield put(fetchReimbursementFailure(error.message || "Network error"));
  }
}

export function* watchReimbursementRequest() {
  yield takeLatest(POST_REIMBURSEMENT_REQUEST, postReimbursementSaga);
  yield takeLatest(FETCH_REIMBURSEMENT_REQUEST, fetchReimbursementSaga);
}
