import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_EDUCATION_REQUEST,
  addEducationSuccess,
  addEducationFailure,
  EDIT_EDUCATION_REQUEST,
  editEducationSuccess,
  editEducationFailure,
  DELETE_EDUCATION_REQUEST,
  deleteEducationSuccess,
  deleteEducationFailure,
  FETCH_EDUCATION_REQUEST,
  fetchEducationSuccess,
  fetchEducationFailure,
} from "./actions";
import config from "../../config";
import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

function* fetchEducationSaga(action) {
  try {
    // Check if token is defined
    if (!token) {
      throw new Error("Token is undefined");
    }

    // const url = `${baseUrl}/auth/get/user/education-details/${action.payload.id}?status=${action.payload.status}`;
    const url = `${baseUrl}/auth/get/user/education-list/${action.payload.id}`;
    // console.log("Fetching from URL:", url);

    // Perform fetch
    const response = yield call(fetch, url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("Fetch response received:", response);

    // Check if response is ok, otherwise throw error
    if (!response.ok) {
      const errorData = yield response.json();
      console.error("Fetch failed with error:", errorData);
      yield put(
        fetchEducationFailure(
          errorData.message || "Failed to fetch education details"
        )
      );
      return;
    }

    // Parse JSON response
    let data;
    try {
      data = yield response.json();
      // console.log("Fetched data:", data);
    } catch (jsonError) {
      console.error("Error parsing JSON:", jsonError);
      throw new Error("Invalid JSON response from server");
    }

    // Dispatch success action
    yield put(fetchEducationSuccess(data));
  } catch (error) {
    console.error("Error in fetchEducationSaga:", error);
    yield put(fetchEducationFailure(error.message || "An error occurred"));
  }
}

function* addEducationSaga(action) {
  try {
    const response = yield call(
      fetch,
      `${baseUrl}/auth/add/user/education-details`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (!response.ok) {
      yield put(addEducationFailure(data.message));
    } else {
      yield put(addEducationSuccess(data));
    }
  } catch (error) {
    yield put(addEducationFailure(error.message));
  }
}

function* editEducationSaga(action) {
  console.log("Starting editEducationSaga with action:", action);
  try {
    const response = yield call(
      fetch,
      `${baseUrl}/auth/update/user/education-details/${action.payload.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (!response.ok) {
      yield put(editEducationFailure(data.message));
    } else {
      yield put(editEducationSuccess(data));
    }
  } catch (error) {
    yield put(editEducationFailure(error.message));
  }
}

function* deleteEducationSaga(action) {
  try {
    // Destructure the ID from the action payload
    const { id } = action.payload;
    console.log("deletedu", id);

    const response = yield call(
      fetch,
      `${baseUrl}/auth/delete/user/education-details/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`, // Ensure the token is correctly accessed
        },
      }
    );

    if (response.ok) {
      // Dispatch success action with the id that was deleted
      yield put(deleteEducationSuccess(id));
    } else {
      // Handle the response if it isn't OK (e.g., 400, 404, 500)
      const data = yield response.json(); // Some error message from the backend
      yield put(
        deleteEducationFailure(
          data.message || "Failed to delete education record."
        )
      );
    }
  } catch (error) {
    // Handle any unexpected errors
    yield put(
      deleteEducationFailure(error.message || "An unexpected error occurred.")
    );
  }
}

export function* watchEducationActions() {
  yield takeLatest(FETCH_EDUCATION_REQUEST, fetchEducationSaga);
  yield takeLatest(ADD_EDUCATION_REQUEST, addEducationSaga);
  yield takeLatest(EDIT_EDUCATION_REQUEST, editEducationSaga);
  yield takeLatest(DELETE_EDUCATION_REQUEST, deleteEducationSaga);
}
