// actions.js
export const POST_REGULARIZE_REQUEST = "POST_REGULARIZE_REQUEST";
export const POST_REGULARIZE_SUCCESS = "POST_REGULARIZE_SUCCESS";
export const POST_REGULARIZE_FAILURE = "POST_REGULARIZE_FAILURE";

export const postRegularizeRequest = (payload) => ({
  type: POST_REGULARIZE_REQUEST,
  payload,
});

export const postRegularizeSuccess = (response) => ({
  type: POST_REGULARIZE_SUCCESS,
  response,
});

export const postRegularizeFailure = (error) => ({
  type: POST_REGULARIZE_FAILURE,
  error,
});


//fetch
// actions.js
export const FETCH_REGULARIZE_REQUEST = "FETCH_REGULARIZE_REQUEST";
export const FETCH_REGULARIZE_SUCCESS = "FETCH_REGULARIZE_SUCCESS";
export const FETCH_REGULARIZE_FAILURE = "FETCH_REGULARIZE_FAILURE";

export const fetchRegularizeRequest = () => ({
  type: FETCH_REGULARIZE_REQUEST,
});

export const fetchRegularizeSuccess = (data) => ({
  type: FETCH_REGULARIZE_SUCCESS,
  data,
});

export const fetchRegularizeFailure = (error) => ({
  type: FETCH_REGULARIZE_FAILURE,
  error,
});
