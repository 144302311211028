// persionaldetails/reducers.js
import {
    FETCH_PROFESSIONAL_DETAILS_SUCCESS,
    FETCH_PROFESSIONAL_DETAILS_FAILURE, 
    UPDATE_PROFESSIONAL_DETAILS_SUCCESS,
    UPDATE_PROFESSIONAL_DETAILS_FAILURE,
  } from './actions';
  
  const initialState = {
    data: {},
    loading: false,
    error: null,
    updateStatus: [],
  };
  
  const professionalDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PROFESSIONAL_DETAILS_SUCCESS:
        return { ...state, loading: false, data: action.payload };
      case FETCH_PROFESSIONAL_DETAILS_FAILURE:
        return { ...state, loading: false, error: action.payload };
  
      // Update actions
      case UPDATE_PROFESSIONAL_DETAILS_SUCCESS:
        return { ...state, loading: false, updateStatus: 'success', data: action.payload };
      case UPDATE_PROFESSIONAL_DETAILS_FAILURE:
        return { ...state, loading: false, updateStatus: 'failure', error: action.payload };  
      default:
        return state;
    }
  };
  
  export default professionalDetailsReducer;
  