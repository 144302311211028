import {
  FETCH_FAMILY_DETAILS_SUCCESS,
  FETCH_FAMILY_DETAILS_FAILURE,
  UPDATE_FAMILY_DETAILS_REQUEST,  // Added to handle loading state for updates
  UPDATE_FAMILY_DETAILS_SUCCESS,
  UPDATE_FAMILY_DETAILS_FAILURE,
} from './actions';

const initialState = {
  data: [],
  loading: false,
  error: null,
  updateStatus: null,  // Changed to store more detailed update status
};

const familyDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
      // Fetch family details
      case FETCH_FAMILY_DETAILS_SUCCESS:
          return { ...state, loading: false, data: action.payload };
      case FETCH_FAMILY_DETAILS_FAILURE:
          return { ...state, loading: false, error: action.payload };

      // Update family details
      case UPDATE_FAMILY_DETAILS_REQUEST:
          return { ...state, loading: true, updateStatus: null }; // Start loading for the update
      case UPDATE_FAMILY_DETAILS_SUCCESS:
          return {
              ...state,
              loading: false,
              updateStatus: { status: 'success', data: action.payload }, // Store updated data on success
              data: action.payload,  // Update the family data immediately
          };
      case UPDATE_FAMILY_DETAILS_FAILURE:
          return {
              ...state,
              loading: false,
              updateStatus: { status: 'failure', message: action.payload },  // Include error message in updateStatus
              error: action.payload,
          };
      default:
          return state;
  }
};

export default familyDetailsReducer;



// import {
//     FETCH_FAMILY_DETAILS_SUCCESS,
//     FETCH_FAMILY_DETAILS_FAILURE, 
//     UPDATE_FAMILY_DETAILS_SUCCESS,
//     UPDATE_FAMILY_DETAILS_FAILURE,
//   } from './actions';
  
//   const initialState = {
//     data: [],
//     loading: false,
//     error: null,
//     updateStatus: [],
//   };
  
//   const familyDetailsReducer = (state = initialState, action) => {
//     switch (action.type) {
//       case FETCH_FAMILY_DETAILS_SUCCESS:
//         return { ...state, loading: false, data: action.payload };
//       case FETCH_FAMILY_DETAILS_FAILURE:
//         return { ...state, loading: false, error: action.payload };
  
//       // Update actions
//       case UPDATE_FAMILY_DETAILS_SUCCESS:
//         return { ...state, loading: false, updateStatus: 'success', data: action.payload };
//       case UPDATE_FAMILY_DETAILS_FAILURE:
//         return { ...state, loading: false, updateStatus: 'failure', error: action.payload };  
//       default:
//         return state;
//     }
//   };
  
//   export default familyDetailsReducer;
  