import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_EXPENSE_REQUEST,
  fetchExpenseSuccess,
  fetchExpenseFailure,
  DELETE_EXPENSE_REQUEST,
  deleteExpenseSuccess,
  deleteExpenseFailure,
  UPDATE_EXPENSE_STATUS_REQUEST,
  updateExpenseStatusSuccess,
  updateExpenseStatusFailure,
  fetchExpenseRequest,
} from "./actions";
import config from "../../config";
import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

function* fetchExpenseSaga() {
  try {
    const response = yield call(() =>
      fetch(`${baseUrl}/auth/get/all-users/reimbursement/list`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }).then((res) => res.json())
    );
    yield put(fetchExpenseSuccess(response));
  } catch (error) {
    yield put(fetchExpenseFailure(error.message));
  }
}

function* deleteExpenseSaga(action) {
  try {
    yield call(() =>
      fetch(`${baseUrl}/auth/delete/user/reimbursement/${action.payload}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
    );
    yield put(deleteExpenseSuccess(action.payload));
    yield put(fetchExpenseRequest()); 
  } catch (error) {
    yield put(deleteExpenseFailure(error.message));
  }
}

function* updateExpenseStatusSaga(action) {
  try {
    const response = yield call(() =>
      fetch(`${baseUrl}/admin/update/reimbursement/status`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }).then((res) => res.json())
    );
    yield put(updateExpenseStatusSuccess(action.payload));
    yield put(fetchExpenseRequest()); // Refresh expenses after status update
  } catch (error) {
    yield put(updateExpenseStatusFailure(error.message));
  }
}

// Watcher Saga
export default function* watchExpenseSaga() {
  yield takeLatest(FETCH_EXPENSE_REQUEST, fetchExpenseSaga);
  yield takeLatest(DELETE_EXPENSE_REQUEST, deleteExpenseSaga);
  yield takeLatest(UPDATE_EXPENSE_STATUS_REQUEST, updateExpenseStatusSaga);
}
