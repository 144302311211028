import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_EXPERIENCE_REQUEST,
  addExperienceSuccess,
  addExperienceFailure,
  EDIT_EXPERIENCE_REQUEST,
  editExperienceSuccess,
  editExperienceFailure,
  DELETE_EXPERIENCE_REQUEST,
  deleteExperienceSuccess,
  deleteExperienceFailure,
  FETCH_EXPERIENCE_REQUEST,
  fetchExperienceSuccess,
  fetchExperienceFailure,
} from "./actions";
import config from "../../config";
import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

function* fetchExperienceSaga(action) {
  try {
    // Check if token is defined
    if (!token) {
      throw new Error("Token is undefined");
    }

    // const url = `${baseUrl}/auth/get/user/EXPERIENCE-details/${action.payload.id}?status=${action.payload.status}`;
    const url = `${baseUrl}/auth/get/user/experiences/userId/${action.payload.id}`;
    // console.log("Fetching from URL:", url);

    // Perform fetch
    const response = yield call(fetch, url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("Fetch response received:", response);

    // Check if response is ok, otherwise throw error
    if (!response.ok) {
      const errorData = yield response.json();
      console.error("Fetch failed with error:", errorData);
      yield put(
        fetchExperienceFailure(
          errorData.message || "Failed to fetch EXPERIENCE details"
        )
      );
      return;
    }

    // Parse JSON response
    let data;
    try {
      data = yield response.json();
      // console.log("Fetched data:", data);
    } catch (jsonError) {
      console.error("Error parsing JSON:", jsonError);
      throw new Error("Invalid JSON response from server");
    }

    // Dispatch success action
    yield put(fetchExperienceSuccess(data));
  } catch (error) {
    console.error("Error in fetchEXPERIENCESaga:", error);
    yield put(fetchExperienceFailure(error.message || "An error occurred"));
  }
}

function* addExperienceSaga(action) {
  try {
    const response = yield call(
      fetch,
      `${baseUrl}/auth/add/user/experience`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (!response.ok) {
      yield put(addExperienceFailure(data.message));
    } else {
      yield put(addExperienceSuccess(data));
    }
  } catch (error) {
    yield put(addExperienceFailure(error.message));
  }
}

function* editExperienceSaga(action) {
  console.log("Starting editEXPERIENCESaga with action:", action);

  try {
    const response = yield call(
      fetch,
      `${baseUrl}/auth/update/user/experience/${action.payload.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(action.payload),
      }
    );

    // Check if response is JSON before parsing
    const contentType = response.headers.get("Content-Type");

    if (!response.ok) {
      // If response is an error, try to parse JSON or log as is
      let errorData;
      if (contentType && contentType.includes("application/json")) {
        errorData = yield response.json();
      } else {
        errorData = { message: "Server responded with an unexpected format" };
        console.error(
          "Server responded with HTML or non-JSON content:",
          errorData
        );
      }
      yield put(
        editExperienceFailure(
          errorData.message || "Failed to edit EXPERIENCE details"
        )
      );
      return;
    }

    // Handle JSON response when status is OK
    let data;
    if (contentType && contentType.includes("application/json")) {
      data = yield response.json();
      console.log("Received JSON response:", data);
      yield put(editExperienceSuccess(data));
    } else {
      console.error("Expected JSON, but received unexpected response format");
      yield put(
        editExperienceFailure("Unexpected response format from server")
      );
    }
  } catch (error) {
    console.error("Caught an error in editEXPERIENCESaga:", error);
    yield put(
      editExperienceFailure(error.message || "An unexpected error occurred")
    );
  }
}

function* deleteExperienceSaga(action) {
  try {
    // Destructure the ID from the action payload
    const { id } = action.payload;
    console.log("deletedu", id);

    const response = yield call(
      fetch,
      `${baseUrl}/auth/delete/user/experience/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`, // Ensure the token is correctly accessed
        },
      }
    );

    if (response.ok) {
      // Dispatch success action with the id that was deleted
      yield put(deleteExperienceSuccess(id));
    } else {
      // Handle the response if it isn't OK (e.g., 400, 404, 500)
      const data = yield response.json(); // Some error message from the backend
      yield put(
        deleteExperienceFailure(
          data.message || "Failed to delete EXPERIENCE record."
        )
      );
    }
  } catch (error) {
    // Handle any unexpected errors
    yield put(
      deleteExperienceFailure(error.message || "An unexpected error occurred.")
    );
  }
}

export function* watchExperienceActions() {
  yield takeLatest(FETCH_EXPERIENCE_REQUEST, fetchExperienceSaga);
  yield takeLatest(ADD_EXPERIENCE_REQUEST, addExperienceSaga);
  yield takeLatest(EDIT_EXPERIENCE_REQUEST, editExperienceSaga);
  yield takeLatest(DELETE_EXPERIENCE_REQUEST, deleteExperienceSaga);
}
