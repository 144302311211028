import {
    GET_PERMISSIONS_REQUEST,
    GET_PERMISSIONS_SUCCESS,
    GET_PERMISSIONS_FAILURE,
    ADD_PERMISSIONS_REQUEST,
    ADD_PERMISSIONS_SUCCESS,
    ADD_PERMISSIONS_FAILURE,
    ASSIGN_PERMISSION_REQUEST,
    ASSIGN_PERMISSION_SUCCESS,
    ASSIGN_PERMISSION_FAILURE,
} from './actions';

const initialPermissionState = {
    success: false,
    error: null,
    data: [],
};

const userPermissionsReducer = (state = initialPermissionState, action) => {
    switch (action.type) {
        case GET_PERMISSIONS_REQUEST:
            return {
                ...state,
                success: false,
                error: null,
            };
        case GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                success: true,
                data: action.payload,
            };
        case GET_PERMISSIONS_FAILURE:
            return {
                ...state,
                success: false,
                error: action.payload,
            };
        case ADD_PERMISSIONS_REQUEST:
            return {
                ...state,
                success: false,
                error: null,
            };
        case ADD_PERMISSIONS_SUCCESS:
            return {
                ...state,
                success: true,
                data: [...state.data, action.payload],
            };
        case ADD_PERMISSIONS_FAILURE:
            return {
                ...state,
                success: false,
                error: action.payload,
            };
        case ASSIGN_PERMISSION_REQUEST:
            return {
                ...state,
                success: false,
                error: null,
            };
        case ASSIGN_PERMISSION_SUCCESS:
            return {
                ...state,
                success: true,
                data: state.data.map(permission => 
                    permission.userId === action.payload.userId
                        ? { ...permission, ...action.payload.permissionData }
                        : permission
                ),
            };
        case ASSIGN_PERMISSION_FAILURE:
            return {
                ...state,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default userPermissionsReducer;
