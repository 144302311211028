import { all } from "redux-saga/effects";

import authSaga from "./auth/saga";
import layoutSaga from "./layout/saga";
import { watchAddAttendance, watchDeleteAttendance, watchEditAttendance, watchFetchAttendance, watchFetchAttendanceOverview, watchFetchAttendanceOverviewDetail } from "./attendance/saga";
import { watchFetchPersonalDetails, watchUpdatePersonalDetails } from './persionaldetails/saga';
import { watchFetchUsers } from "./users/saga";
import { watchEmployeeActions } from "./commonSaga/webSaga";
import { watchFetchProfessionalDetails , watchUpdateProfessionalDetails} from "./professionaldetails/saga";
import { watchUserRoleActions } from "./userRoles/saga";
import { watchPermissionsActions } from "./userPermissions/saga";
import { watchFetchFamilyDetails, watchUpdateFamilyDetails } from "./familiydetails/saga";
import { watchFetchBankDetails, watchUpdateBankDetails } from "./bankdetails/saga";
import { watchEducationActions } from "./educationdetails/saga";
import { watchProjectActions } from "./projectdetails/saga";
import { watchUserFetchAttendance } from "./userattendancerecord/saga";
import { watchUserDailyFetchAttendance, } from "./dailyuserattendance/saga";
import { watchExperienceActions } from "./experiencedetails/saga";
import { watchOfficeActions } from "./office/saga";
import { watchRegularizeRequest } from "./userregularize/saga";
import { watchLeaveRequest } from "./userleaveapply/saga";
import { watchReimbursementRequest } from "./userreimbursement/saga";
import { watchRegularization } from "./adminRegularization/saga";
import { watchLeave } from "./adminLeave/saga";
import { watchHolidayActions } from "./holiday/saga";
import watchExpenseSaga from "./adminExpense/saga";
import { watchFetchNotifications } from "./notification/saga";


















// Employee saga

export default function* rootSaga() {
  yield all([
    authSaga(),
    layoutSaga(),
    watchFetchAttendance(),
    watchFetchPersonalDetails(),
    watchUpdatePersonalDetails(),
    watchFetchUsers(),
    watchEmployeeActions(),
    watchFetchProfessionalDetails(),
    watchUpdateProfessionalDetails(),
    watchUserRoleActions(),
    watchPermissionsActions(),
    watchFetchFamilyDetails(),
    watchUpdateFamilyDetails(),
    watchFetchBankDetails(),
    watchUpdateBankDetails(),
    watchEducationActions(),
    watchProjectActions(),
    watchUserFetchAttendance(),
    watchUserDailyFetchAttendance(),
    watchExperienceActions(),
    watchUpdateBankDetails,
    watchOfficeActions(),
    watchRegularizeRequest(),
    watchLeaveRequest(),
    watchReimbursementRequest(),
    watchRegularization(),
    watchLeave(),
    watchHolidayActions(),
    watchExpenseSaga(),
    watchFetchNotifications(),
    watchFetchAttendanceOverview(),
    watchAddAttendance(),
    watchEditAttendance(),
    watchDeleteAttendance(),
    watchFetchAttendanceOverviewDetail(),













    // Employee saga




    
  ]);
}

