// actions.js

// For POST Reimbursement
export const POST_REIMBURSEMENT_REQUEST = "POST_REIMBURSEMENT_REQUEST";
export const POST_REIMBURSEMENT_SUCCESS = "POST_REIMBURSEMENT_SUCCESS";
export const POST_REIMBURSEMENT_FAILURE = "POST_REIMBURSEMENT_FAILURE";

export const postReimbursementRequest = (payload) => ({
  type: POST_REIMBURSEMENT_REQUEST,
  payload,
});

export const postReimbursementSuccess = (response) => ({
  type: POST_REIMBURSEMENT_SUCCESS,
  response,
});

export const postReimbursementFailure = (error) => ({
  type: POST_REIMBURSEMENT_FAILURE,
  error,
});

// For FETCH Reimbursement
export const FETCH_REIMBURSEMENT_REQUEST = "FETCH_REIMBURSEMENT_REQUEST";
export const FETCH_REIMBURSEMENT_SUCCESS = "FETCH_REIMBURSEMENT_SUCCESS";
export const FETCH_REIMBURSEMENT_FAILURE = "FETCH_REIMBURSEMENT_FAILURE";

export const fetchReimbursementRequest = () => ({
  type: FETCH_REIMBURSEMENT_REQUEST,
});

export const fetchReimbursementSuccess = (data) => ({
  type: FETCH_REIMBURSEMENT_SUCCESS,
  data,
});

export const fetchReimbursementFailure = (error) => ({
  type: FETCH_REIMBURSEMENT_FAILURE,
  error,
});
