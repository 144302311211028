import { call, put, takeLatest } from "redux-saga/effects";
import {
  POST_LEAVE_REQUEST,
  postLeaveSuccess,
  postLeaveFailure,
  FETCH_LEAVE_REQUEST,
  fetchLeaveSuccess,
  fetchLeaveFailure,
} from "./actions";

// Assuming token and baseUrl are defined or passed in
import config from "../../config";
import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

function* postLeaveSaga(action) {
  console.log("leaveacrtion", action)
  try {
    const response = yield call(
      fetch,
      `${baseUrl}/auth/add/user/leave/request`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (!response.ok) {
      // Handle non-200 HTTP status codes as errors
      yield put(postLeaveFailure(data.message || "An error occurred"));
    } else {
      yield put(postLeaveSuccess(data));
    }
  } catch (error) {
    yield put(postLeaveFailure(error.message || "Network error"));
  }
}

function* fetchLeaveSaga() {
    let userIds;
    let userRole;
    const AUTH_SESSION_KEY = "userInfo";
    const userDataString = sessionStorage.getItem(AUTH_SESSION_KEY);
    if (userDataString) {
      try {
        const parsedUser = JSON.parse(userDataString);
        if (parsedUser && parsedUser.data && parsedUser.data.userInfo) {
          userRole = parsedUser.data.userInfo;
          userIds = userRole.id;
          // console.log("useridleave", userIds)
        } else {
          console.error("userInfo is undefined in user data.");
        }
      } catch (error) {
        console.error("Failed to parse user data from sessionStorage:", error);
      }
    } else {
      console.log("No user data found in sessionStorage.");
    }
    
  try {
    const response = yield call(
      fetch,
      `${baseUrl}/auth/get/user/leave-request/userId/${userIds}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (!response.ok) {
      // Handle non-200 HTTP status codes as errors
      yield put(fetchLeaveFailure(data.message || "An error occurred"));
    } else {
      yield put(fetchLeaveSuccess(data));
    }
  } catch (error) {
    yield put(fetchLeaveFailure(error.message || "Network error"));
  }
}
export function* watchLeaveRequest() {
  yield takeLatest(POST_LEAVE_REQUEST, postLeaveSaga);
  yield takeLatest(FETCH_LEAVE_REQUEST, fetchLeaveSaga);
}
