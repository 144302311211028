// persionaldetails/reducers.js
import {
  FETCH_PERSONAL_DETAILS_SUCCESS,
  FETCH_PERSONAL_DETAILS_FAILURE, 
  UPDATE_PERSONAL_DETAILS_SUCCESS,
  UPDATE_PERSONAL_DETAILS_FAILURE,
} from './actions';

const initialState = {
  data: [],
  loading: false,
  error: null,
  updateStatus: [],
};

const personalDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PERSONAL_DETAILS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case FETCH_PERSONAL_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Update actions
    case UPDATE_PERSONAL_DETAILS_SUCCESS:
      return { ...state, loading: false, updateStatus: 'success', data: action.payload };
    case UPDATE_PERSONAL_DETAILS_FAILURE:
      return { ...state, loading: false, updateStatus: 'failure', error: action.payload };  
    default:
      return state;
  }
};

export default personalDetailsReducer;
