import config from "../../config";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_FAMILY_DETAILS_REQUEST,
  fetchFamilyDetailsSuccess,
  fetchFamilyDetailsFailure,
} from "./actions";
import {
  UPDATE_FAMILY_DETAILS_REQUEST,
  updateFamilyDetailsSuccess,
  updateFamilyDetailsFailure,
} from "./actions";
let baseUrl = config.API_URL
function* fetchFamilyDetailsSaga(action) {
  const { userId, token } = action.payload;
  
  // console.log(userId, token);

  try {
    const response = yield call(
      fetch,
      `${baseUrl}/auth/get/user/family-details/${userId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // console.log("API Response Status:", response.status);

    if (response.ok) {
      const data = yield response.json();

      // console.log("API Data:", data); // Log data to ensure it's received
      yield put(fetchFamilyDetailsSuccess(data));
    } else {
      const errorMessage = yield response.text();
      console.error("API Error:", errorMessage); // Log error message
      yield put(fetchFamilyDetailsFailure(errorMessage));
    }
  } catch (error) {
    console.error("Request Error:", error); // Catch and log any request errors
    yield put(fetchFamilyDetailsFailure(error.message));
  }
}



// function* updateFamilyDetailsSaga(action) {
//   const { userId, token, updatedData } = action.payload;

//   // console.log("Token:", token);
//   console.log("Updated Data FAM:", updatedData);

//   if (!userId) {
//     console.error("userId is undefined");
//     return; // Exit if userId is not defined
//   }

//   try {
//     const response = yield call(
//       fetch,
//       `${baseUrl}/auth/update/user/family-details/${userId}`,
//       {
//         method: "PUT",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify(updatedData),
//       }
//     );

//     console.log("Response Status:", response.status);

//     if (response.ok) {
//       const data = yield response.json();
//       console.log("Data returned from API:", data); // Log the exact response data
        
//       yield put(updateFamilyDetailsSuccess(data));
//     } else {
//       const errorMessage = yield response.text(); // Log the error response body
//       console.error("Error Message from API:", errorMessage);

//       yield put(updateFamilyDetailsFailure(errorMessage));
//     }
//   } catch (error) {
//     console.error("Error in Saga:", error.message); // Log the error if the fetch fails
//     yield put(updateFamilyDetailsFailure(error.message));
//   }
// }



function* updateFamilyDetailsSaga(action) {
  const { userId, token, updatedData } = action.payload;

  // console.log("Token:", token);
  console.log("Updated Data:", updatedData);

  if (!userId) {
    console.error("userId is undefined");
    return; // Exit if userId is not defined
  }

  try {
    const response = yield call(
      fetch,
      `${baseUrl}/auth/update/user/family-details/${userId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      }
    );

    console.log("Response Status:", response.status);

    if (response.ok) {
      const data = yield response.json();
      console.log("Data returned from API:", data); // Log the exact response data

      yield put(updateFamilyDetailsSuccess(data));
    } else {
      const errorMessage = yield response.text(); // Log the error response body
      console.error("Error Message from API:", errorMessage);

      yield put(updateFamilyDetailsFailure(errorMessage));
    }
  } catch (error) {
    console.error("Error in Saga:", error.message); // Log the error if the fetch fails
    yield put(updateFamilyDetailsFailure(error.message));
  }
}

export function* watchUpdateFamilyDetails() {
  yield takeLatest(
    UPDATE_FAMILY_DETAILS_REQUEST,
    updateFamilyDetailsSaga
  );
}

export function* watchFetchFamilyDetails() {
  yield takeLatest(
    FETCH_FAMILY_DETAILS_REQUEST,
    fetchFamilyDetailsSaga
  );
}


