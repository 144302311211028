export const FETCH_USER_ATTENDANCE_REQUEST = 'FETCH_USER_ATTENDANCE_REQUEST';
export const FETCH_USER_ATTENDANCE_SUCCESS = 'FETCH_USER_ATTENDANCE_SUCCESS';
export const FETCH_USER_ATTENDANCE_FAILURE = 'FETCH_USER_ATTENDANCE_FAILURE';

export const fetchUserAttendanceRequest = (userId, month, year) => ({
    type: FETCH_USER_ATTENDANCE_REQUEST,
    payload: { userId, month, year },
});


export const fetchUserAttendanceSuccess = (data) => ({
    type: FETCH_USER_ATTENDANCE_SUCCESS,
    payload: data,
});

export const fetchUserAttendanceFailure = (error) => ({
    type: FETCH_USER_ATTENDANCE_FAILURE,
    payload: error,
});