import {
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE,
  } from "./actions";
  
  const initialState = {
    data: [],
    error: null,
  };
  
  const usersReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_USERS_SUCCESS:
        return { ...state, data: action.payload, error: null };
  
      case FETCH_USERS_FAILURE:
        return { ...state, error: action.payload };
  
      default:
        return state;
    }
  };
  
  export default usersReducer;
  