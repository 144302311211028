import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_HOLIDAY_REQUEST, addHolidaySuccess, addHolidayFailure,
  UPDATE_HOLIDAY_REQUEST, updateHolidaySuccess, updateHolidayFailure,
  FETCH_HOLIDAYS_REQUEST, fetchHolidaysSuccess, fetchHolidaysFailure,
  DELETE_HOLIDAY_REQUEST, deleteHolidaySuccess, deleteHolidayFailure,
} from './actions';
import config from '../../config';
import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

// Add Holiday Saga
function* addHolidaySaga(action) {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/add/holiday`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (!response.ok) yield put(addHolidayFailure(data.message));
    else yield put(addHolidaySuccess(data));
  } catch (error) {
    yield put(addHolidayFailure(error.message));
  }
}

// Update Holiday Saga
function* updateHolidaySaga(action) {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/update/holiday/${action.payload.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (!response.ok) yield put(updateHolidayFailure(data.message));
    else yield put(updateHolidaySuccess(data));
  } catch (error) {
    yield put(updateHolidayFailure(error.message));
  }
}

// Fetch Holidays Saga
function* fetchHolidaysSaga() {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/get/holiday/list`, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` },
    });
    const data = yield response.json();
    if (!response.ok) yield put(fetchHolidaysFailure(data.message));
    else yield put(fetchHolidaysSuccess(data));
  } catch (error) {
    yield put(fetchHolidaysFailure(error.message));
  }
}

// Delete Holiday Saga
function* deleteHolidaySaga(action) {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/delete/holiday/${action.payload}`, {
      method: 'DELETE',
      headers: { 'Authorization': `Bearer ${token}` },
    });
    if (response.ok) yield put(deleteHolidaySuccess(action.payload));
    else {
      const data = yield response.json();
      yield put(deleteHolidayFailure(data.message));
    }
  } catch (error) {
    yield put(deleteHolidayFailure(error.message));
  }
}

// Watcher function
export function* watchHolidayActions() {
  yield takeLatest(ADD_HOLIDAY_REQUEST, addHolidaySaga);
  yield takeLatest(UPDATE_HOLIDAY_REQUEST, updateHolidaySaga);
  yield takeLatest(FETCH_HOLIDAYS_REQUEST, fetchHolidaysSaga);
  yield takeLatest(DELETE_HOLIDAY_REQUEST, deleteHolidaySaga);
}
