// persionaldetails/actions.js
export const FETCH_PROFESSIONAL_DETAILS_REQUEST = 'FETCH_PROFESSIONAL_DETAILS_REQUEST';
export const FETCH_PROFESSIONAL_DETAILS_SUCCESS = 'FETCH_PROFESSIONAL_DETAILS_SUCCESS';
export const FETCH_PROFESSIONAL_DETAILS_FAILURE = 'FETCH_PROFESSIONAL_DETAILS_FAILURE';

export const UPDATE_PROFESSIONAL_DETAILS_REQUEST = 'UPDATE_PROFESSIONAL_DETAILS_REQUEST';
export const UPDATE_PROFESSIONAL_DETAILS_SUCCESS = 'UPDATE_PROFESSIONAL_DETAILS_SUCCESS';
export const UPDATE_PROFESSIONAL_DETAILS_FAILURE = 'UPDATE_PROFESSIONAL_DETAILS_FAILURE';

// persionaldetails/actions.js
export const fetchProfessionalDetailsRequest = (payload) => ({
  type: FETCH_PROFESSIONAL_DETAILS_REQUEST,
  payload: payload,
});


export const fetchProfessionalDetailsSuccess = (data) => ({
  type: FETCH_PROFESSIONAL_DETAILS_SUCCESS,
  payload: data,
});

export const fetchProfessionalDetailsFailure = (error) => ({
  type: FETCH_PROFESSIONAL_DETAILS_FAILURE,
  payload: error,
});




export const updateProfessionalDetailsRequest = (payload) => ({
  type: UPDATE_PROFESSIONAL_DETAILS_REQUEST,
  payload: payload,
});

export const updateProfessionalDetailsSuccess = (data) => ({
  type: UPDATE_PROFESSIONAL_DETAILS_SUCCESS,
  payload: data,
});

export const updateProfessionalDetailsFailure = (error) => ({
  type: UPDATE_PROFESSIONAL_DETAILS_FAILURE,
  payload: error,
});
