import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_PERMISSIONS_REQUEST,
  getPermissionsSuccess,
  getPermissionsFailure,
  ADD_PERMISSIONS_REQUEST,
  addPermissionsSuccess,
  addPermissionsFailure,
  ASSIGN_PERMISSION_REQUEST,
  assignPermissionSuccess,
  assignPermissionFailure,
} from './actions';
import config from '../../config';
import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

// Saga to fetch user permissions
function* getPermissionsSaga() {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/get/user/permissions`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const data = yield response.json();
    
    if (!response.ok) {
      yield put(getPermissionsFailure(data.message));
    } else {
      yield put(getPermissionsSuccess(data));
    }
  } catch (error) {
    yield put(getPermissionsFailure(error.message));
  }
}

// Saga to add new permissions
function* addPermissionsSaga(action) {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/add/user/permission`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();

    if (!response.ok) {
      yield put(addPermissionsFailure(data.message));
    } else {
      yield put(addPermissionsSuccess(data));
    }
  } catch (error) {
    yield put(addPermissionsFailure(error.message));
  }
}

// Saga to assign permissions to a user
function* assignPermissionSaga(action) {
  const { userId, permissionData } = action.payload;
  
  try {
    const response = yield call(fetch, `${baseUrl}/auth/assign/user/permission/${userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({permission_ids: permissionData}),
    });

    const data = yield response.json();

    if (!response.ok) {
      yield put(assignPermissionFailure(data.message));
    } else {
      yield put(assignPermissionSuccess(data));
    }
  } catch (error) {
    yield put(assignPermissionFailure(error.message));
  }
}

// Watcher Saga for user permission actions
export function* watchPermissionsActions() {
  yield takeLatest(GET_PERMISSIONS_REQUEST, getPermissionsSaga);
  yield takeLatest(ADD_PERMISSIONS_REQUEST, addPermissionsSaga);
  yield takeLatest(ASSIGN_PERMISSION_REQUEST, assignPermissionSaga);
}
