export const ADD_EXPERIENCE_REQUEST = 'ADD_EXPERIENCE_REQUEST';
export const ADD_EXPERIENCE_SUCCESS = 'ADD_EXPERIENCE_SUCCESS';
export const ADD_EXPERIENCE_FAILURE = 'ADD_EXPERIENCE_FAILURE';

export const EDIT_EXPERIENCE_REQUEST = 'EDIT_EXPERIENCE_REQUEST';
export const EDIT_EXPERIENCE_SUCCESS = 'EDIT_EXPERIENCE_SUCCESS';
export const EDIT_EXPERIENCE_FAILURE = 'EDIT_EXPERIENCE_FAILURE';

export const DELETE_EXPERIENCE_REQUEST = 'DELETE_EXPERIENCE_REQUEST';
export const DELETE_EXPERIENCE_SUCCESS = 'DELETE_EXPERIENCE_SUCCESS';
export const DELETE_EXPERIENCE_FAILURE = 'DELETE_EXPERIENCE_FAILURE';

export const FETCH_EXPERIENCE_REQUEST = 'FETCH_EXPERIENCE_REQUEST';
export const FETCH_EXPERIENCE_SUCCESS = 'FETCH_EXPERIENCE_SUCCESS';
export const FETCH_EXPERIENCE_FAILURE = 'FETCH_EXPERIENCE_FAILURE';    


// Actions for adding an EXPERIENCE
export const addExperienceRequest = (formData) => ({
  type: ADD_EXPERIENCE_REQUEST,
  payload: formData,
});

export const addExperienceSuccess = (data) => ({
  type: ADD_EXPERIENCE_SUCCESS,
  payload: data,
});

export const addExperienceFailure = (error) => ({
  type: ADD_EXPERIENCE_FAILURE,
  payload: error,
});

// Actions for editing an EXPERIENCE
export const editExperienceRequest = (experienceData) => ({
  type: EDIT_EXPERIENCE_REQUEST,
  payload: experienceData,
});

export const editExperienceSuccess = (updatedExperience) => ({
  type: EDIT_EXPERIENCE_SUCCESS,
  payload: updatedExperience,
});

export const editExperienceFailure = (error) => ({
  type: EDIT_EXPERIENCE_FAILURE,
  payload: error,
});

// Actions for deleting an EXPERIENCE

export const deleteExperienceRequest = (experienceId) => ({
  type: DELETE_EXPERIENCE_REQUEST,
  payload: experienceId,
});

export const deleteExperienceSuccess = (experienceId) => ({
  type: DELETE_EXPERIENCE_SUCCESS,
  payload: {experienceId},
});

export const deleteExperienceFailure = (error) => ({
  type: DELETE_EXPERIENCE_FAILURE,
  payload: error,
});

// Actions for updating user status

// Action creator for requesting EXPERIENCE data
export const fetchExperienceRequest = (statusData) => ({
  type: FETCH_EXPERIENCE_REQUEST,
  payload: statusData,  // Expecting statusData to contain necessary parameters, like id and status
});

// Action creator for handling a successful response
export const fetchExperienceSuccess = (data) => ({
  type: FETCH_EXPERIENCE_SUCCESS,
  payload: data, // Contains the fetched EXPERIENCE data
});

// Action creator for handling an error response
export const fetchExperienceFailure = (error) => ({
  type: FETCH_EXPERIENCE_FAILURE,
  payload: error, // Contains the error message or error object
});
