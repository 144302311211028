import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_REGULARIZATION_REQUEST,
  fetchRegularizationSuccess,
  fetchRegularizationFailure,
  DELETE_REGULARIZATION_REQUEST,
  deleteRegularizationSuccess,
  deleteRegularizationFailure,
  UPDATE_REGULARIZATION_STATUS_REQUEST,
  updateRegularizationStatusSuccess,
  updateRegularizationStatusFailure,
} from './actions';
import config from '../../config';

import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();
const baseUrl = config.API_URL;
const token = api.getLoggedInUser()?.token.token;

function* fetchRegularizationSaga() {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/get/all-users/regularization/list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const data = yield response.json();
    yield put(fetchRegularizationSuccess(data));
  } catch (error) {
    yield put(fetchRegularizationFailure(error.message));
  }
}

function* deleteRegularizationSaga(action) {
  try {
    const response = yield call(fetch, `${baseUrl}/auth/delete/user/regularization/${action.payload}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    yield put(deleteRegularizationSuccess(action.payload));
  } catch (error) {
    yield put(deleteRegularizationFailure(error.message));
  }
}

function* updateRegularizationStatusSaga(action) {
  try {
    const { att_id, type } = action.payload;
    const response = yield call(fetch, `${baseUrl}/admin/update/regularization/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({  att_id, type }),
    });
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    yield put(updateRegularizationStatusSuccess( att_id, type));
  } catch (error) {
    yield put(updateRegularizationStatusFailure(error.message));
  }
}

export function* watchRegularization() {
  yield takeLatest(FETCH_REGULARIZATION_REQUEST, fetchRegularizationSaga);
  yield takeLatest(DELETE_REGULARIZATION_REQUEST, deleteRegularizationSaga);
  yield takeLatest(UPDATE_REGULARIZATION_STATUS_REQUEST, updateRegularizationStatusSaga);
}
