import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_PERSONAL_DETAILS_REQUEST,
  fetchPersonalDetailsSuccess,
  fetchPersonalDetailsFailure,
} from './actions';
import {
  UPDATE_PERSONAL_DETAILS_REQUEST,
  updatePersonalDetailsSuccess,
  updatePersonalDetailsFailure,
} from './actions';

function* fetchPersonalDetailsSaga(action) {
  const { userId ,token } = action.payload;
  // console.log(userId, token);

  try {
    const response = yield call(fetch, `https://tys-hrms-app.onrender.com/auth/get/user/personal-details/${userId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // console.log("API Response Status:", response.status);

    if (response.ok) {
      const data = yield response.json();
   
      // console.log("API Data:", data); // Log data to ensure it's received
      yield put(fetchPersonalDetailsSuccess(data));
    } else {
      const errorMessage = yield response.text();
      console.error("API Error:", errorMessage); // Log error message
      yield put(fetchPersonalDetailsFailure(errorMessage));
    }
  } catch (error) {
    console.error("Request Error:", error); // Catch and log any request errors
    yield put(fetchPersonalDetailsFailure(error.message));
  }
}

function* updatePersonalDetailsSaga(action) {
  const { userId, token, updatedData } = action.payload;
  // console.log("tols", token , updatedData)
  if (!userId) {
    console.error("userId is undefined");
    return; // exit if userId is not defined
  }
  try {
    const response = yield call(fetch, `https://tys-hrms-app.onrender.com/auth/update/user/personal-details/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updatedData),

    });

    if (response.ok) {
      const data = yield response.json();
      // console.log("data", data)
      yield put(updatePersonalDetailsSuccess(data));
    } else {
      const errorMessage = yield response.text();
      yield put(updatePersonalDetailsFailure(errorMessage));
    }
  } catch (error) {
    yield put(updatePersonalDetailsFailure(error.message));
  }
}

export function* watchUpdatePersonalDetails() {
  yield takeLatest(UPDATE_PERSONAL_DETAILS_REQUEST, updatePersonalDetailsSaga);
}
export function* watchFetchPersonalDetails() {
  yield takeLatest(FETCH_PERSONAL_DETAILS_REQUEST, fetchPersonalDetailsSaga);
}

