export const ADD_EDUCATION_REQUEST = 'ADD_EDUCATION_REQUEST';
export const ADD_EDUCATION_SUCCESS = 'ADD_EDUCATION_SUCCESS';
export const ADD_EDUCATION_FAILURE = 'ADD_EDUCATION_FAILURE';

export const EDIT_EDUCATION_REQUEST = 'EDIT_EDUCATION_REQUEST';
export const EDIT_EDUCATION_SUCCESS = 'EDIT_EDUCATION_SUCCESS';
export const EDIT_EDUCATION_FAILURE = 'EDIT_EDUCATION_FAILURE';

export const DELETE_EDUCATION_REQUEST = 'DELETE_EDUCATION_REQUEST';
export const DELETE_EDUCATION_SUCCESS = 'DELETE_EDUCATION_SUCCESS';
export const DELETE_EDUCATION_FAILURE = 'DELETE_EDUCATION_FAILURE';

export const FETCH_EDUCATION_REQUEST = 'FETCH_EDUCATION_REQUEST';
export const FETCH_EDUCATION_SUCCESS = 'FETCH_EDUCATION_SUCCESS';
export const FETCH_EDUCATION_FAILURE = 'FETCH_EDUCATION_FAILURE';    

// export const UPDATE_USER_STATUS_REQUEST = "UPDATE_USER_STATUS_REQUEST";
// export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";
// export const UPDATE_USER_STATUS_FAILURE = "UPDATE_USER_STATUS_FAILURE";

// Actions for adding an eDUCATION
export const addEducationRequest = (formData) => ({
  type: ADD_EDUCATION_REQUEST,
  payload: formData,
});

export const addEducationSuccess = (data) => ({
  type: ADD_EDUCATION_SUCCESS,
  payload: data,
});

export const addEducationFailure = (error) => ({
  type: ADD_EDUCATION_FAILURE,
  payload: error,
});

// Actions for editing an eDUCATION
export const editEducationRequest = (educationData) => ({
  type: EDIT_EDUCATION_REQUEST,
  payload: educationData,
});

export const editEducationSuccess = (updatedEducation) => ({
  type: EDIT_EDUCATION_SUCCESS,
  payload: updatedEducation,
});

export const editEducationFailure = (error) => ({
  type: EDIT_EDUCATION_FAILURE,
  payload: error,
});

// Actions for deleting an eDUCATION

export const deleteEducationRequest = (educationId) => ({
  type: DELETE_EDUCATION_REQUEST,
  payload: educationId,
});

export const deleteEducationSuccess = (educationId) => ({
  type: DELETE_EDUCATION_SUCCESS,
  payload: educationId,
});

export const deleteEducationFailure = (error) => ({
  type: DELETE_EDUCATION_FAILURE,
  payload: error,
});

// Actions for updating user status

// Action creator for requesting education data
export const fetchEducationRequest = (statusData) => ({
  type: FETCH_EDUCATION_REQUEST,
  payload: statusData,  // Expecting statusData to contain necessary parameters, like id and status
});

// Action creator for handling a successful response
export const fetchEducationSuccess = (data) => ({
  type: FETCH_EDUCATION_SUCCESS,
  payload: data, // Contains the fetched education data
});

// Action creator for handling an error response
export const fetchEducationFailure = (error) => ({
  type: FETCH_EDUCATION_FAILURE,
  payload: error, // Contains the error message or error object
});
